import React, { useState, useRef, useEffect } from "react";
//import axios from "axios";

import { withAuth } from "@okta/okta-react";
import UploadModal from "./UploadModal";
import config from "../../configs/appSettings";
import Loading from "../../components/Loading";

//import jwt_decode from "jwt-decode";

import {
  FILE_PROCESSING_STATES,
  getFileExtension,
  processFile,
  getUploadURL,
  httpCall,
  mapCoordinates
} from "./createJobHelper";

const FileUpload = props => {  
  const inputFileRef = useRef();
  const [fileProcessing, setFileProcessing] = useState(false);
  const [uploading, setUploading] = useState(FILE_PROCESSING_STATES.NONE);
  const [coordinates, setCoordinates] = useState(FILE_PROCESSING_STATES.NONE);

  const [choosingCurves, setChoosingCurves] = useState(
    FILE_PROCESSING_STATES.NONE
  );
  const [uploadedFilename] = useState("");
  const [previousUploadedFile, setPreviousUploadedFile] = useState("");

  async function getAccessToken() {
    const accessToken = await props.auth.getAccessToken();
    return accessToken;
  }

  const stop = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const fileUploadHandler = e => {
    stop(e);
    inputFileRef.current.click();
  };

  const sampleFileHandler = async e => {
    props.settgsSample(true);
    props.setdemoCurve(false);
    
  };

  const handleDragEnter = e => {
    stop(e);
  };
  const handleDragLeave = e => {
    stop(e);
  };
  const handleDragOver = e => {
    stop(e);
  };
  const handleFileDrop = e => {
    stop(e);
    handleFile(e.dataTransfer.files[0]);
  };

  const handleChangeFile = e => handleFile(e.target.files[0]);

  const resetStatus = () => {
    setUploading(FILE_PROCESSING_STATES.NONE);
    setCoordinates(FILE_PROCESSING_STATES.NONE);
    setChoosingCurves(FILE_PROCESSING_STATES.NONE);
    props.actions.resetState();
  };

  async function handleFile(file) {
    if (file.size > config.maximumFileSize) {
      props.actions.showErrorModal("File size exceeded");
      return;
    } else if (!(getFileExtension(file.name) === "las")) {
      props.actions.showErrorModal("Only LAS files are allowed");
      return;
    } else if (file.name === previousUploadedFile) {
      props.actions.showErrorModal(
        "You have already uploaded this file. Please select another LAS or refresh the page."
      );
      return;
    }
    setPreviousUploadedFile(file.name);
    resetStatus();

    setFileProcessing(true);
    setUploading(FILE_PROCESSING_STATES.LOADING);

    const { filename, blobData, contentType } = await processFile(file);
    await uploadFile(filename, blobData, contentType);
  }

  async function uploadFile(filename, blobData, contentType) {
    const fetchOptions = {
      method: "PUT",
      body: blobData
    };
    try {
      const { uploadURL, s3LasPath } = await getUploadURL(
        filename,
        contentType,
        getAccessToken
      );

      const response = await fetch(uploadURL, fetchOptions);
      if (!response.ok) throw new Error("Upload Failed");

      setUploading(ps => (ps = FILE_PROCESSING_STATES.COMPLETED));
      props.actions.updateFilePath("uploadedFile", filename);
      props.actions.updateFilePath("las", s3LasPath);
      props.setLasElementShow(false);
      await getCoordinatesAndMnemonics(s3LasPath);
      props.actions.updateConverting();
      props.actions.updateCount(2);
    } catch (err) {
      console.log("err: ", err);
      setUploading(FILE_PROCESSING_STATES.FAILED);
    }
  }

  async function getCoordinatesAndMnemonics(s3path) {
    try {
      const [
        [coordinatesResponse, basinResponse],
        mnemonicsResponse
      ] = await Promise.all([
        getCoordinatesAndGetBasin(s3path),
        rankMnemonics(s3path)
      ]);

      props.actions.updateCoordinates(coordinatesResponse);
      props.actions.updateRankMnemonics(mnemonicsResponse);
      props.actions.updateBasin(basinResponse);
      setFileProcessing(false);
      document.getElementById("step2").scrollIntoView();
    } catch (err) {}
  }

  async function rankMnemonics(s3_file_path) {    
    if(props.tgsSample === false){
    setChoosingCurves(p => (p = FILE_PROCESSING_STATES.LOADING));
    let url = `${config.endpoints.arlas}/rankMnemonics`;
    const payload = {
      s3_file_path
    };

    try {
      let [, data] = await httpCall(url, payload, getAccessToken);
      
      setChoosingCurves(p => (p = FILE_PROCESSING_STATES.COMPLETED));
      return data;
      
    } catch (err) {
      setChoosingCurves(p => (p = FILE_PROCESSING_STATES.FAILED));
      return {};
    }
  }
  else{
    
    let activeDemoMnemonics = config.demoFlowLongLat.filter(function(e) {
      
      return s3_file_path === `s3://${e.inputPath}`;
    });
    
    let data = activeDemoMnemonics[0].demoRankMnemonics;    
    return data;
    
  }
  }

  async function getLatLong(s3_file_path) {
    setCoordinates("loading");
    let url = `${config.endpoints.arlas}/getLatLong`;

    const payload = {
      s3_file_path
    };

    try {
      const [, data] = await httpCall(url, payload, getAccessToken);
      setCoordinates(FILE_PROCESSING_STATES.COMPLETED);
      return mapCoordinates(data);
    } catch (err) {
      setCoordinates(FILE_PROCESSING_STATES.FAILED);
      return {};
    }
  }

  async function getBasin({ latitude, longitude }) {
    //const url = `${config.endpoints.arlas}/getBasin/latitude/${latitude}/longitude/${longitude}`;
    const url = `${config.endpoints.arlas}/GetBasinForLatLong`;

    let inputBasin = {
      latitude: latitude,
      longitude: longitude
    };
    try {
      const [, data] = await httpCall(url, inputBasin, getAccessToken, "POST");

      return data;
    } catch (err) {
      console.log("Getting basin failed");
      return [];
    }
  }

  async function getCoordinatesAndGetBasin(s3Pth) {    
    let coordinatesResponse;
    if(props.tgsSample === false){
    coordinatesResponse = await getLatLong(s3Pth);
    }
    else{
      let activeDemogetLatLong = config.demoFlowLongLat.filter(function(e) {
      
        return s3Pth === `s3://${e.inputPath}`;
      });
      
      coordinatesResponse = {latitude: activeDemogetLatLong[0].latitude, longitude:activeDemogetLatLong[0].longtitude}
      
    }
    
    const basinResponse = await getBasin(coordinatesResponse);

    return [coordinatesResponse, basinResponse];

  }

  function removeFile() {
    props.actions.updateFilePath("uploadedFile", "");
    props.actions.updateFilePath("las", "");
    props.actions.updateBasin("");
    FILE_PROCESSING_STATES.INVALIDLAS = false;
    props.setfilterCurve(true);
    props.setdemoCurve(true);
  }

  async function demoRank(rankPath, rankName) {   
    
    //props.setfilterCurve(true);
    props.setmnemonicCALI(false);
    props.setsingleMnemonic(false);
    props.setmnemonicGR(false);
    props.setmnemonicDPR(false);
    props.setmnemonicNEU(false);
    props.setmnemonicBDEN(false);
    props.setLasElementShow(false);
    props.setfilterCurve(false);
    props.setdemoCurve(false);
    props.setdemoLoading(true);
    props.actions.updateFilePath("uploadedFile", rankName);
    props.actions.updateFilePath("las", rankPath);
    await getCoordinatesAndMnemonics(rankPath);
    props.setdemoCurve(true);
    props.setviewArlas(false);
    props.setdemoLoading(false);
  }

  useEffect(() => {
    let activeWells = config.demoFlowLongLat.filter(function(e) {      
      return e.longtitude === props.mapLatLong;
    });
    if (activeWells.length > 0) {      
      demoRank(`s3://${activeWells[0].inputPath}`, `${activeWells[0].name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapLatLong]);

  return (
    <>
      <UploadModal
        show={fileProcessing}
        uploading={uploading}
        coordinates={coordinates}
        choosingCurves={choosingCurves}
      />
      <div
        className="upload-file"
        onDrop={e => handleFileDrop(e)}
        onDragOver={e => handleDragOver(e)}
        onDragEnter={e => handleDragEnter(e)}
        onDragLeave={e => handleDragLeave(e)}
      >
        {/* add this class for validation fail "mandatory-field" */}
        <div className="intro-text">
          <p>
            We currently support one LAS conversion at a time up to 50
            conversions. Repeat these steps for each LAS. For high volume
            conversions, please contact your sales representative.
          </p>

          {!props.decodeConversion && props.conversionObjColor !== null ? (
            <p style={{ color: props.conversionObjColor, marginTop: "20px" }}>
              You have {props.conversionmaxcountObj - props.conversioncountObj}{" "}
              of {props.conversionmaxcountObj} free ARLAS conversions remaining.
            </p>
          ) : null}
        </div>
        {!props.decodeConversion && props.conversionObjColor === null ? (
          <div
            style={{
              height: "285px",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div style={{ marginTop: "140px" }}>
              <Loading loading={true} />
            </div>
          </div>
        ) : null}

        {!props.conversionreach && props.conversionObjColor !== null ? (
          <div
            className="upload-section ma"
            id="uploadsection"
            style={{
              display:
                props.data.filePaths.uploadedFile === "" ? "block" : "none"
            }}
          >
            <div className="row ml-0 mr-0">
              <div className="col-3 text-center">
                <input
                  type="file"
                  ref={inputFileRef}
                  name="lasFile"
                  id="browsefile"
                  className="d-none"
                  value={uploadedFilename}
                  onChange={handleChangeFile}
                />
                <button
                  id="upload"
                  className="arlas-btn secondary browse-file"
                  onClick={fileUploadHandler}
                >
                  Browse File
                </button>
              </div>
              <div className="col-2 text-center">
                <div className="set-or">OR</div>
              </div>
              <div className="col-3 drag-file text-center">
                <div className="drag-icon"></div>
                <div className="drag-text">Drag and Drop files</div>
              </div>
              <div className="col-1 text-center">
                <div className="set-or">OR</div>
              </div>
              
              <div className="col-3 text-center">
                <button
                  id="tgsSample"
                  className="arlas-btn secondary browse-file"
                  onClick={sampleFileHandler}
                >
                  Use TGS Sample
                </button>
              </div>
            </div>
            <button
              id="uploadmodalbtn"
              className="arlas-btn d-none"
              data-toggle="modal"
              data-target="#uploadmodal"
              data-keyboard="true"
            >
              Upload
            </button>
          </div>
        ) : null}
 
{props.conversionmaxcountObj - props.conversioncountObj === 0 && props.decodeConversion ===false && props.conversioncountObj!==null ?(
          <div
            className="upload-section ma"
            id="uploadsection"
            style={{
              display:
                props.data.filePaths.uploadedFile === "" ? "block" : "none"
            }}
          >
            <div className="row ml-0 mr-0">         
             
              <div className="col-12 text-center">
                <button
                  id="tgsSample"                 
                  className="arlas-btn secondary browse-file"
                  onClick={sampleFileHandler}
                >
                  Use TGS Sample
                </button>
              </div>
            </div>
            <button
              id="uploadmodalbtn"
              className="arlas-btn d-none"
              data-toggle="modal"
              data-target="#uploadmodal"
              data-keyboard="true"
            >
              Upload
            </button>
          </div>
        ) : null}



        {/* --- */}
        {props.conversionreach &&
        props.conversionmaxcountObj !== props.conversioncountObj &&
        props.conversionmaxcountObj >= props.conversioncountObj  ? (
          <div
            className="upload-section ma"
            id="uploadsection"
            style={{
              display:
                props.data.filePaths.uploadedFile === "" ? "block" : "none"
            }}
          >
            <div className="row ml-0 mr-0">
              <div className="col-3 text-center">
                <input
                  type="file"
                  ref={inputFileRef}                  
                  name="lasFile"
                  id="browsefile"
                  className="d-none"
                  value={uploadedFilename}
                  onChange={handleChangeFile}
                />
                <button
                  id="upload"
                  data-reach={props.conversionreach}                
                  
                  className="arlas-btn secondary browse-file"
                  onClick={fileUploadHandler}
                >
                  Browse File
                </button>
              </div>
              <div className="col-2 text-center">
                <div className="set-or">OR</div>
              </div>
              <div className="col-3 drag-file text-center">
                <div className="drag-icon"></div>
                <div className="drag-text">Drag and Drop files</div>
              </div>
              <div className="col-1 text-center">
                <div className="set-or">OR</div>
              </div>
              <div className="col-3 text-center">
                <button
                  id="tgsSample"
                  className="arlas-btn secondary browse-file"
                  onClick={sampleFileHandler}
                >
                  Use TGS Sample
                </button>
              </div>
            </div>
            <button
              id="uploadmodalbtn"
              className="arlas-btn d-none"
              data-toggle="modal"
              data-target="#uploadmodal"
              data-keyboard="true"
            >
              Upload
            </button>
          </div>
        ) : null}

        {/* --- */}
        <div
          className="uploaded-section"
          id="uploadedsection"
          style={{
            display: props.data.filePaths.uploadedFile !== "" ? "block" : "none"
          }}
        >
          <div className="file-name-section">
            <label>File Name</label>
            <div>
              <span className="file-name">
                {props.data.filePaths.uploadedFile}
              </span>
              <button
                type="button"
                className="close-custom"
                onClick={removeFile}
              >
                X
              </button>
            </div>
            <p className="mandatory-text mt-15 d-none">
              File Not supported. Upload valid .LAS file only
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(FileUpload);
