import React from "react";
import phoneIcon from "../assets/images/phone_icon_white.png";
const AppFooter = props => {
  return (
    <footer className="arlas-footer">
      <nav>
        <ul>
          <li>
            &copy;2020 TGS&nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li>
            <a
              href="https://www.tgs.com/trademark"
              target="_blank"
              rel="noreferrer noopener"
            >
              Trademark
            </a>
            &nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li>
            <a
              href="https://www.tgs.com/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </a>
            &nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li>
            <a
              href="https://www.tgs.com/terms-and-conditions"
              target="_blank"
              rel="noreferrer noopener"
            >
              Terms & Conditions
            </a>
            &nbsp;
            <span className="footer-pipe">|</span>
          </li>
          <li className="contact-us">
            <a
              href="https://www.tgs.com/contact-us"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={phoneIcon} className="phone-icon" alt="phone icon" />
              <span>Contact Us</span>
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default AppFooter;
