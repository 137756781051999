import React from "react";
import MnemonicCategory from "./MnemonicCategory";
import MnemonicList from "./MnemonicList";

const Mnemonic = ({
  mnemonics,
  allMnemonics,
  value,
  name,
  include,
  displayName,
  onChange,
  onChangeCheckbox
}) => {
  const filteredMnemonics = allMnemonics.filter(x => !mnemonics.includes(x));

  return (
    <div className={`mnemonics-curve ${!include && "deselect"}`}>
      <div className="row">
        <div className="col-7 curve-title">{displayName}</div>
        <div className="col-5 curve-select text-right">
          <label className="arlas-checkbox">
            Use this curve
            <input
              type="checkbox"
              name={`${name}Include`}
              id={`${name}`}
              checked={include}
              onChange={onChangeCheckbox}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
      <div className="row mt-15">
        <div className="col-7">
          <MnemonicCategory
            data={mnemonics}
            name={name}
            value={value}
            onChange={onChange}
            disabled={!include}
          />
        </div>
        <div className="col-5 text-right mt-5px">
          <MnemonicList
            data={filteredMnemonics}
            name={name}
            value={value}
            onChange={onChange}
            disabled={!include}
          />
        </div>
      </div>
    </div>
  );
};

export default Mnemonic;
