import React, { useState, useEffect } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import Loading from "../../components/Loading";
import ModalContainer from "../../components/ModalContainer";
import done from "../../assets/images/icons/done.svg";
import { useCreateJobHook } from "../CreateJob/useCreateJobHook";
import error from "../../assets/images/icons/error.svg";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import config from "../../configs/appSettings";
const Done = () => <img src={done} alt="done" title="Completed" />;
const Error = () => <img src={error} alt="error" title="Failed" />;

const ConvertModal = (
  {
    show,
    arlascurvepath,
    lascurvepath,
    mnemonics,
    onHide,
    converting,
    enableDownloadButton,
    handleDownloadFile,
    token,
    changebtnText,
    lasload,
    viewOption,
    basinname,
    arlasReqID,
    tgsSample,
    viewArlas,
    setviewArlas,
    LasElementShow,
    setLasElementShow
  },
  props
) => {
  const { footerElement, setfooterElement } = useCreateJobHook(props);
  const [compareModeOn, setCompareModeOn] = useState(false);
  const [confidenceModeOn, setconfidenceModeOn] = useState(false);
  const [disableConfidence, setdisableConfidence] = useState(false);
  //const [footerElement, setfooterElement] = useState(false);
  // const [LasElementShow, setLasElementShow] = useState(false);

  const [progress, setProgress] = useState(0);
  const [noError, setnoError] = useState(null);
  const [starsSelected, selectStar] = useState("");
  const [ratingToken, setratingToken] = useState("");
  useEffect(() => {
    if (converting === true) {
      setProgress(0);
    }
    if (converting === false) {
      setProgress(60);
    }
  }, [converting]);

  useEffect(() => {
    if (compareModeOn === true) {
      setdisableConfidence(true);
    } else {
      setdisableConfidence(false);
    }
  }, [compareModeOn, confidenceModeOn]);
  //Compare to Orginal Button function Start

  const compareOrginal = () => {
    setCompareModeOn(!compareModeOn);
    //setconfidenceModeOn(false);
    console.log(confidenceModeOn);
    document.querySelector(".arlasbox").style.display = "flex";
    document.querySelector(".lasbox").style.display = "none";
    if (!compareModeOn) {
      if (confidenceModeOn === false) {
        let p95Chart = document.querySelectorAll(".p95Chart");
        for (var i = 0; i < p95Chart.length; i++) {
          p95Chart[i].setAttribute("visibility", "hidden");
        }
        let nonp95Chart = document.querySelectorAll(".nonp95Chart");
        for (var k = 0; k < nonp95Chart.length; k++) {
          nonp95Chart[k].setAttribute("visibility", "visible");
        }
      }

      let purpleChart = document.querySelectorAll(".purpleChart");
      for (var j = 0; j < purpleChart.length; j++) {
        purpleChart[j].setAttribute("visibility", "hidden");
      }
    } else {
      if (confidenceModeOn === false) {
        let p95Chartshow = document.querySelectorAll(".p95Chart");
        for (var l = 0; l < p95Chartshow.length; l++) {
          p95Chartshow[l].setAttribute("visibility", "visible");
        }
        let nonp95Chartshow = document.querySelectorAll(".nonp95Chart");
        for (var m = 0; m < nonp95Chartshow.length; m++) {
          nonp95Chartshow[m].setAttribute("visibility", "visible");
        }
      }

      let purpleChart = document.querySelectorAll(".purpleChart");
      for (var n = 0; n < purpleChart.length; n++) {
        purpleChart[n].setAttribute("visibility", "visible");
      }
    }
  };
  //Compare to Orginal Button function End

  //Confidence Interval Button Function Start
  const showConfidenceInterval = () => {
    setconfidenceModeOn(!confidenceModeOn);
    //setCompareModeOn(false);
    document.querySelector(".arlasbox").style.display = "flex";
    document.querySelector(".lasbox").style.display = "none";
    if (!confidenceModeOn) {
      let p95Chart = document.querySelectorAll(".p95Chart");
      for (var p = 0; p < p95Chart.length; p++) {
        p95Chart[p].setAttribute("visibility", "hidden");
      }
      let purpleChart = document.querySelectorAll(".purpleChart");
      for (var q = 0; q < purpleChart.length; q++) {
        purpleChart[q].setAttribute("visibility", "visible");
      }
      let nonp95Chart = document.querySelectorAll(".nonp95Chart");
      for (var r = 0; r < nonp95Chart.length; r++) {
        nonp95Chart[r].setAttribute("visibility", "visible");
      }
    } else {
      let p95Chartshow = document.querySelectorAll(".p95Chart");
      for (var s = 0; s < p95Chartshow.length; s++) {
        p95Chartshow[s].setAttribute("visibility", "visible");
      }
      let nonp95Chartshow = document.querySelectorAll(".nonp95Chart");
      for (var t = 0; t < nonp95Chartshow.length; t++) {
        nonp95Chartshow[t].setAttribute("visibility", "visible");
      }
      let purpleChart = document.querySelectorAll(".purpleChart");
      for (var u = 0; u < purpleChart.length; u++) {
        purpleChart[u].setAttribute("visibility", "visible");
      }
    }
  };
  //Confidence Interval Button Function End

  //Show Footer Element Function Start
  
  const showfooterElement = () => {
    setProgress(100);
    setfooterElement(true);
   
  };  
 
  const showfooterElementHide = () => {
    setProgress(30);
    setnoError(false);
  };
  const LasElementHandler = () => {
    setProgress(30);
    setLasElementShow(true);
    changebtnText();
  };
  const modalCloseHandler = () => {
    setProgress(60);
    setnoError(null);
    if (converting) {
      alert(
        "Please wait until ARLAS Conversion completes before closing window."
      );
    } else {
      setfooterElement(false);
      onHide(false);
      arlasFeedbackHandler();
      selectStar("");
    }
  };

  //Arlas Feedback Api Start

  const arlasfeebackToken = token => {
    setratingToken(token);
  };

  const arlasFeedbackHandler = async () => {
    const reqID = arlasReqID;
    const feedbackToken = ratingToken;
    const feedbackUrl = `${config.endpoints.arlas}/SubmitArlasUserFeedback`;
    const feedBackInputdata = {
      user_email: localStorage.getItem("userEmail"),
      request_id: reqID,
      feedback_rating: starsSelected.toString()
    };
    const feedbackHeaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + feedbackToken
      }
    };
    axios
      .post(feedbackUrl, feedBackInputdata, feedbackHeaders)
      .then(
        res => {
          //console.log(res);
        },
        error => {
          console.log("error");
        }
      )
      .catch(err => {});
  };
  //Arlas Feedback Api End

  const Star = ({ selected = false, onClick = f => f }) => (
    <span className={selected ? "star selected" : "star"} onClick={onClick} />
  );

  const StarRating = ({ totalStars }) => {
    return (
      <span className="star-rating">
        {[...Array(totalStars)].map((n, i) => (
          <Star
            key={i}
            selected={i < starsSelected}
            onClick={() => selectStar(i + 1)}
          />
        ))}
      </span>
    );
  };

  //Show Footer Element Function End
  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      centered
      dialogClassName="arlas-convert-modal"
      className="modalResize"
      data-keyboard="false"
      backdrop="static"
    >
      <Modal.Header
        style={{ borderBottom: "none", padding: "0px", paddingTop: "10px" }}
      >
        {/* <h4 className="modal-title">ARLAS Viewer</h4> */}
        <button
          type="button"
          className="jobhistoryclose-custom"
          onClick={modalCloseHandler}
        >
          X
        </button>
      </Modal.Header>
      <Modal.Body className="jobmodal-body">
        <div
          id="lasArlasProgressBar"
          style={{
            position: "relative",
            marginBottom: "40px",
            marginTop: "20px"
          }}
        >
          <div className="clearfix"></div>
          <div className="process-text"></div>
          <div className="progress-bar-text-area">
            <div className="progress-bar-text">
              Loading LAS {""}
              <Loading loading={!LasElementShow} />
              {LasElementShow ? <Done /> : null}
            </div>
            {converting !== null ? (
              <div className="progress-bar-text">
                Converting to ARLAS {""}
                <Loading loading={converting} />
                {!converting ? <Done /> : null}
              </div>
            ) : (
              <div className="progress-bar-text">
                Converting to ARLAS {""}
                {!converting ? <Error /> : null}
              </div>
            )}
            {/* <div className="progress-bar-text">
              Loading ARLAS {""}
              <Loading loading={!footerElement} />
              {footerElement ? <Done /> : null}
            </div> */}
            {noError === null ? (
              <div className="progress-bar-text">
                Loading ARLAS {""}
                <Loading loading={!footerElement} />
                {footerElement ? <Done /> : null}
              </div>
            ) : (
              <div className="progress-bar-text">
                Loading ARLAS {""}
                {!noError ? <Error /> : <Done />}
              </div>
            )}
          </div>
          <ProgressBar
            variant="success"
            now={progress}
            bsPrefix="progress-bar-path-Loader"
          />
        </div>
        <ModalContainer
          laspath={lascurvepath}
          arlaspath={arlascurvepath}
          mnemonics={mnemonics}
          token={token}
          arlasready={converting}
          showfooterElementsHandler={showfooterElement}
          LasElement={LasElementHandler}
          basinSelection={basinname}
          feedbackToken={arlasfeebackToken}
          tgsSample={tgsSample}
          viewArlas={viewArlas}
          setviewArlas={setviewArlas}
        />
      </Modal.Body>
      <Modal.Footer>
        {footerElement ? (
          <ul className="arlaslegend arlasfooter">
            <li className="arlaslegend-item arlaslegend-measured">
              <div className="arlaslegend-box"></div>
              <div className="arlaslegend-label">Measured</div>
            </li>
            <li className="arlaslegend-item arlaslegend-predicted">
              <div className="arlaslegend-box"></div>
              <div className="arlaslegend-label">Predicted</div>
            </li>
            <li className="arlaslegend-item arlaslegend-confidence">
              <div className="arlaslegend-box"></div>
              <div className="arlaslegend-label">
                Confidence Interval (P-5<span className="legendspace"> - </span>
                P-95)
              </div>
            </li>
            <li className="arlaslegend-item arlastextrating">
              <span className="ratingtext">
                Please rate the quality of this ARLAS
              </span>
              {/* <div id="rating" onClick={e => arlasRating(e)}>
                <span className="fa fa-star-o arlaschecked arlasrating"></span>
                <span className="fa fa-star-o arlaschecked arlasrating"></span>
                <span className="fa fa-star-o arlaschecked arlasrating"></span>
                <span className="fa fa-star-o arlasrating"></span>
                <span className="fa fa-star-o arlasrating"></span>
              </div> */}

              <StarRating totalStars={5} />
            </li>
          </ul>
        ) : null}

        {footerElement ? (
          <button
            className="arlas-btn arlas-btn-modal1 primary ma float-left arlasfooter"
            style={{ marginLeft: "85px" }}
            onClick={compareOrginal}
          >
            <span>
              {compareModeOn ? "Revert to ARLAS" : "Compare to Orginal"}
            </span>
          </button>
        ) : null}

        {footerElement ? (
          <button
            className="arlas-btn arlas-btn-modal2 primary ma float-left arlasfooter"
            style={{ marginLeft: "20px" }}
            onClick={showConfidenceInterval}
            disabled={disableConfidence}
          >
            <span>{confidenceModeOn ? "Show" : "Hide"}</span> Confidence
            Interval
          </button>
        ) : null}

        {footerElement ? (
          <button
            className="arlas-btn arlas-btn-modal primary ma float-left arlasfooter"
            onClick={handleDownloadFile}
            disabled={enableDownloadButton}
          >
            <Loading loading={converting} />
            Download ARLAS
          </button>
        ) : null}

        <button
          className="arlas-btn arlas-btn-modal primary ma float-left"
          onClick={showfooterElementHide}
          id="showFooter"
          style={{ display: "none" }}
        >
          /> ShowFooter
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConvertModal;
