import React from "react";
import { Modal } from "react-bootstrap";

const FormValidationModal = ({ show, onHide, errors }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <h4 className="modal-title">Validation Errors</h4>
        <button type="button" className="close-custom" onClick={onHide}>
          X
        </button>
      </Modal.Header>
      <Modal.Body>
        <h4>Missing required fields</h4>
        <ul>
          {Object.keys(errors).map(key => (
            <li key={key}>{errors[key]}</li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default FormValidationModal;
