const LocalStore = (function () {
  //set object into localstorage
  const setItem = (key, object) => {
    localStorage.setItem(key, JSON.stringify(object));
  };

  //clear object from localstorage
  const clear = (key) => {
    localStorage.removeItem(key);
  };

  //get object from localstorage as json
  const getJSON = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  //clear data if any
//   (function () {
//     localStorage.clear();
//   })();

  return {
    setItem,
    clear,
    getJSON,
  };
})();

export default LocalStore;
