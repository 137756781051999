import React, { useState, useEffect } from "react";
import axios from "axios";
import { withAuth } from "@okta/okta-react";
import { LasChart } from "../components/LasChart";
import { ARLasChart } from "../components/ARLasChart";
import config from "../configs/appSettings";
//import { useCreateJobHook } from "../pages/CreateJob/useCreateJobHook";

const ModalContainer = props => {
  //const { convertionCount, setconvertionCount } = useCreateJobHook(props);
  const [gammaRay, setgammaRay] = useState([]);
  const [deepResistivity, setdeepResistivity] = useState([]);
  const [neutron, setneutron] = useState([]);
  const [sonic, setsonic] = useState([]);
  const [caliper, setcaliper] = useState([]);
  const [bulkDensity, setbulkDensity] = useState([]);

  const [argammaRay, setargammaRay] = useState([]);
  const [ardeepResistivity, setardeepResistivity] = useState([]);
  const [arneutron, setarneutron] = useState([]);
  const [arsonic, setarsonic] = useState([]);

  const [arcaliper, setarcaliper] = useState([]);
  const [arbulkDensity, setarbulkDensity] = useState([]);
  const [artopdepth, setartopdepth] = useState([]);
  const [arbasedepth, setarbasedepth] = useState([]);

  const [argammaRayMinPointForX, setargammaRayMinPointForX] = useState([]);
  const [argammaRayMaxPointForX, setargammaRayMaxPointForX] = useState([]);
  const [
    ardeepResistivityMinPointForX,
    setardeepResistivityMinPointForX
  ] = useState([]);
  const [
    ardeepResistivityMaxPointForX,
    setardeepResistivityMaxPointForX
  ] = useState([]);
  const [arneutronMinPointForX, setarneutronMinPointForX] = useState([]);
  const [arneutronMaxPointForX, setarneutronMaxPointForX] = useState([]);
  const [arsonicMinPointForX, setarsonicMinPointForX] = useState([]);
  const [arsonicMaxPointForX, setarsonicMaxPointForX] = useState([]);
  const [arbulkDensityMinPointForX, setarbulkDensityMinPointForX] = useState(
    []
  );
  const [arbulkDensityMaxPointForX, setarbulkDensityMaxPointForX] = useState(
    []
  );
  const [arcaliperMinPointForX, setarcaliperMinPointForX] = useState([]);
  const [arcaliperMaxPointForX, setarcaliperMaxPointForX] = useState([]);

  const [argammaRayCurveUnit, setargammaRayCurveUnit] = useState([]);
  const [ardeepResistivityCurveUnit, setardeepResistivityCurveUnit] = useState(
    []
  );
  const [arneutronCurveUnit, setarneutronCurveUnit] = useState([]);
  const [arsonicCurveUnit, setarsonicCurveUnit] = useState([]);
  const [arcaliperCurveUnit, setarcaliperCurveUnit] = useState([]);
  const [arbulkDensityCurveUnit, setarbulkDensityCurveUnit] = useState([]);

  const [ardepthUnit, setardepthUnit] = useState([]);

  const [listItem, setlistItem] = useState();
  const [arlistItem, setarlistItem] = useState();
  const [lasreadystate, setlasreadystate] = useState("flex");
  const [arlasreadystate, setarlasreadystate] = useState("none");
  const [arlaserrorstate, setarlaserrorstate] = useState("none");
  const [arlaserrortext, setarlaserrortext] = useState("");

  let url = `${config.endpoints.arlas}/GetCurveDetails`;
  const curveName = [];
  const arcurveName = [
    { gammaRay: "GR_TGSAR" },
    { deepResistivity: "RES_TGSAR" },
    { neutron: "NEUT_TGSAR" },
    { bulkDensity: "DEN_TGSAR" },
    { sonic: "SON_TGSAR" },
    { caliper: "CALI" }
  ];

  if (props.mnemonics.gammaRayInclude) {
    curveName.push({ gammaRay: props.mnemonics.gammaRay });
  }
  if (props.mnemonics.deepResistivityInclude) {
    curveName.push({ deepResistivity: props.mnemonics.deepResistivity });
  }
  if (props.mnemonics.neutronInclude) {
    curveName.push({ neutron: props.mnemonics.neutron });
  }
  if (props.mnemonics.bulkDensityInclude) {
    curveName.push({ bulkDensity: props.mnemonics.bulkDensity });
  }
  if (props.mnemonics.sonicInclude) {
    curveName.push({ sonic: props.mnemonics.sonic });
  }
  if (props.mnemonics.caliperInclude) {
    curveName.push({ caliper: props.mnemonics.caliper });
  }
  //var oktatoken = "";
  async function getAccessToken() {
    const oktaaccessToken = await props.auth.getAccessToken();
    //oktatoken = oktaaccessToken;
    return oktaaccessToken;
  }
  const fetchData = async () => {
    const lasPath = props.laspath;
    const accessToken = await getAccessToken();
    let inputdata = {};
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken
      }
    };

    var laspromise = new Promise((resolve, reject) => {
      let laspromisecnt = 0;
      curveName.forEach((item, index, array) => {
        const curveitem = Object.values(item);
        const curvekey = Object.keys(item);

        inputdata = {
          lasFilePath: lasPath,
          curveName: curveitem.toString()
        };
        const result = axios.post(url, inputdata, headers);
        result.then(res => {
          if (curvekey.toString() === "gammaRay") {
            setgammaRay(gammaRay.push(res.data));
            laspromisecnt = laspromisecnt + 1;
            if (laspromisecnt === curveName.length) {
              props.LasElement();
              resolve();
            }
          } else if (curvekey.toString() === "deepResistivity") {
            setdeepResistivity(deepResistivity.push(res.data));
            laspromisecnt = laspromisecnt + 1;
            if (laspromisecnt === curveName.length) {
              props.LasElement();
              resolve();
            }
          } else if (curvekey.toString() === "neutron") {
            setneutron(neutron.push(res.data));
            laspromisecnt = laspromisecnt + 1;
            if (laspromisecnt === curveName.length) {
              props.LasElement();
              resolve();
            }
          } else if (curvekey.toString() === "sonic") {
            setsonic(sonic.push(res.data));
            laspromisecnt = laspromisecnt + 1;
            if (laspromisecnt === curveName.length) {
              props.LasElement();
              resolve();
            }
          } else if (curvekey.toString() === "bulkDensity") {
            setbulkDensity(bulkDensity.push(res.data));
            laspromisecnt = laspromisecnt + 1;
            if (laspromisecnt === curveName.length) {
              props.LasElement();
              resolve();
            }
          } else if (curvekey.toString() === "caliper") {
            setcaliper(caliper.push(res.data));
            laspromisecnt = laspromisecnt + 1;
            if (laspromisecnt === curveName.length) {
              props.LasElement();
              resolve();
            }
          }
        });
      }); //main for each
    });

    laspromise.then(() => {
      const comItems = curveName.map((itemcurve, index) => {
        const newcitem = Object.keys(itemcurve);
        let tempitem = "";
        if (newcitem[0] === "gammaRay") {
          tempitem = gammaRay;
        } else if (newcitem[0] === "deepResistivity") {
          tempitem = deepResistivity;
        } else if (newcitem[0] === "neutron") {
          tempitem = neutron;
        } else if (newcitem[0] === "sonic") {
          tempitem = sonic;
        } else if (newcitem[0] === "caliper") {
          tempitem = caliper;
        } else if (newcitem[0] === "bulkDensity") {
          tempitem = bulkDensity;
        }

        return (
          <LasChart
            key={index}
            lasInformation={tempitem}
            color="#003660"
            curveName={Object.keys(itemcurve)}
            curveId={Object.values(itemcurve)}
          />
        );
      });
      setlistItem(comItems);
    });
  };

 let arlasCurveRenderCountArray =[] 
  const arlasCurveRenderCount = (renderCurveCount) => {
    // setProgress(100);
    // setfooterElement(true);
    arlasCurveRenderCountArray.push(renderCurveCount)
    if(arlasCurveRenderCountArray.length ===6){
      props.showfooterElementsHandler();
      arlasCurveRenderCountArray =[] 
    }
    
    
  };  

  const manageJobHistory = async () => {
    const lasPath = props.laspath;
    const arlasPath = props.arlaspath;
    const basinSelected = props.basinSelection;
    const historyToken = await getAccessToken();

    const historyUrl = `${config.endpoints.arlas}/ManageJobHistory`;
    const historyInputdata = {
      end_point: "saveOnDemandHistory",
      user_email: localStorage.getItem("userEmail"),
      file_name: props.mnemonics.lasFile,
      las_file_path: lasPath,
      arlas_file_path: arlasPath,
      sap_customer_id: "123456",
      basin: basinSelected
    };
    const historyHeaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + historyToken
      }
    };
    if (props.tgsSample !== true) {
      if (props.viewArlas === true) {
        axios
          .post(historyUrl, historyInputdata, historyHeaders)
          .then(
            res => {
              //console.log(res);
            },
            error => {
              console.log("error");
            }
          )
          .catch(err => {});
      }
    }
  };

  const fetcharlasData = async () => {
    const arlasPath = props.arlaspath;
    const accessToken = await getAccessToken();
    props.feedbackToken(accessToken);
    let arinputdata = {};
    const arheaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken
      }
    };
    props.setviewArlas(true);
    var arlaspromise = new Promise((resolve, reject) => {
      let arlaspromisecnt = 0;
      arcurveName.forEach((item, index) => {
        const arcurveitem = Object.values(item);
        const arcurvekey = Object.keys(item);

        arinputdata = {
          ARLasFilePath: arlasPath,
          curveName: arcurveitem.toString()
        };
        const arresult = axios.post(url, arinputdata, arheaders);
        arresult
          .then(
            res => {
              if (arcurvekey.toString() === "gammaRay") {
                setardepthUnit(ardepthUnit.push(res.data.DepthUnit));
                setargammaRay(
                  argammaRay.push(
                    Object.values(res.data.ARLASCurveInformations)
                  )
                );
                setartopdepth(artopdepth.push(res.data.TopDepth));
                setarbasedepth(arbasedepth.push(res.data.EndDepth));

                setargammaRayMinPointForX(
                  argammaRayMinPointForX.push(res.data.MinPointForX)
                );
                setargammaRayMaxPointForX(
                  argammaRayMaxPointForX.push(res.data.MaxPointForX)
                );

                setargammaRayCurveUnit(
                  argammaRayCurveUnit.push(res.data.CurveUnit)
                );

                arlaspromisecnt = arlaspromisecnt + 1;
                if (arlaspromisecnt === arcurveName.length) {
                  resolve();
                }
              } else if (arcurvekey.toString() === "deepResistivity") {
                setardeepResistivity(
                  ardeepResistivity.push(
                    Object.values(res.data.ARLASCurveInformations)
                  )
                );
                arlaspromisecnt = arlaspromisecnt + 1;
                setardeepResistivityCurveUnit(
                  ardeepResistivityCurveUnit.push(res.data.CurveUnit)
                );

                setardeepResistivityMinPointForX(
                  ardeepResistivityMinPointForX.push(res.data.MinPointForX)
                );
                setardeepResistivityMaxPointForX(
                  ardeepResistivityMaxPointForX.push(res.data.MaxPointForX)
                );

                if (arlaspromisecnt === arcurveName.length) {
                  resolve();
                }
              } else if (arcurvekey.toString() === "neutron") {
                setarneutron(
                  arneutron.push(Object.values(res.data.ARLASCurveInformations))
                );
                arlaspromisecnt = arlaspromisecnt + 1;
                setarneutronCurveUnit(
                  arneutronCurveUnit.push(res.data.CurveUnit)
                );

                setarneutronMinPointForX(
                  arneutronMinPointForX.push(res.data.MinPointForX)
                );
                setarneutronMaxPointForX(
                  arneutronMaxPointForX.push(res.data.MaxPointForX)
                );

                if (arlaspromisecnt === arcurveName.length) {
                  resolve();
                }
              } else if (arcurvekey.toString() === "sonic") {
                setarsonic(
                  arsonic.push(Object.values(res.data.ARLASCurveInformations))
                );
                arlaspromisecnt = arlaspromisecnt + 1;
                setarsonicCurveUnit(arsonicCurveUnit.push(res.data.CurveUnit));
                setarsonicMinPointForX(
                  arsonicMinPointForX.push(res.data.MinPointForX)
                );
                setarsonicMaxPointForX(
                  arsonicMaxPointForX.push(res.data.MaxPointForX)
                );

                if (arlaspromisecnt === arcurveName.length) {
                  resolve();
                }
              } else if (arcurvekey.toString() === "bulkDensity") {
                setarbulkDensity(
                  arbulkDensity.push(
                    Object.values(res.data.ARLASCurveInformations)
                  )
                );
                arlaspromisecnt = arlaspromisecnt + 1;
                setarbulkDensityCurveUnit(
                  arbulkDensityCurveUnit.push(res.data.CurveUnit)
                );
                setarbulkDensityMinPointForX(
                  arbulkDensityMinPointForX.push(res.data.MinPointForX)
                );
                setarbulkDensityMaxPointForX(
                  arbulkDensityMaxPointForX.push(res.data.MaxPointForX)
                );
                if (arlaspromisecnt === arcurveName.length) {
                  resolve();
                }
              } else if (arcurvekey.toString() === "caliper") {
                setarcaliper(
                  arcaliper.push(Object.values(res.data.ARLASCurveInformations))
                );
                arlaspromisecnt = arlaspromisecnt + 1;
                setarcaliperCurveUnit(
                  arcaliperCurveUnit.push(res.data.CurveUnit)
                );
                setarcaliperMinPointForX(
                  arcaliperMinPointForX.push(res.data.MinPointForX)
                );
                setarcaliperMaxPointForX(
                  arcaliperMaxPointForX.push(res.data.MaxPointForX)
                );
                if (arlaspromisecnt === arcurveName.length) {
                  resolve();
                }
              }
            },
            error => {
              console.log(error.response.data.ErrorMessage);
              if (error) {
                setarlasreadystate("none");
                setarlaserrorstate("flex");
                setarlaserrortext(error.response.data.ErrorMessage);
              }
            }
          )
          .catch(error => {
            console.log(error);
          });
      }); //main for each
    });

    arlaspromise.then(() => {
      const arcomItems = arcurveName.map((itemcurve, index) => {
        const arnewcitem = Object.keys(itemcurve);
        let artempitem = "";
        let curveunititem = "";
        let minx = "";
        let maxX = "";

        if (arnewcitem[0] === "gammaRay") {
          artempitem = argammaRay;
          curveunititem = argammaRayCurveUnit;
          minx = argammaRayMinPointForX;
          maxX = argammaRayMaxPointForX;
          
        } else if (arnewcitem[0] === "deepResistivity") {
          artempitem = ardeepResistivity;
          curveunititem = ardeepResistivityCurveUnit;
          minx = ardeepResistivityMinPointForX;
          maxX = ardeepResistivityMaxPointForX;
          
        } else if (arnewcitem[0] === "neutron") {
          artempitem = arneutron;
          curveunititem = arneutronCurveUnit;
          minx = arneutronMinPointForX;
          maxX = arneutronMaxPointForX;
          
        } else if (arnewcitem[0] === "sonic") {
          artempitem = arsonic;
          curveunititem = arsonicCurveUnit;
          minx = arsonicMinPointForX;
          maxX = arsonicMaxPointForX;
          
        } else if (arnewcitem[0] === "caliper") {
          artempitem = arcaliper;
          curveunititem = arcaliperCurveUnit;
          minx = arcaliperMinPointForX;
          maxX = arcaliperMaxPointForX;
          // setTimeout(function() {
            //props.showfooterElementsHandler(arnewcitem[0]);
          // }, 3500);

        } else if (arnewcitem[0] === "bulkDensity") {
          artempitem = arbulkDensity;
          curveunititem = arbulkDensityCurveUnit;
          minx = arbulkDensityMinPointForX;
          maxX = arbulkDensityMaxPointForX;          
        }

        return (
          <ARLasChart
            key={index}
            arlasInformation={artempitem}
            color="green"
            topdepth={artopdepth}
            basedepth={arbasedepth}
            curveName={Object.keys(itemcurve)}
            curveId={Object.values(itemcurve)}
            curveUnit={curveunititem}
            depthUnit={ardepthUnit}
            purl={url}
            path={arlasPath}
            minX={minx}
            maxX={maxX}
            accesstoken={accessToken}
            arlasCurveRenderCount ={arlasCurveRenderCount}
          />
        );
      });
      setarlistItem(arcomItems);
      setlasreadystate("none");
      setarlasreadystate("flex");
    });
  };

  useEffect(() => {
    if (props.arlasready) {
      fetchData();
    }

    if (props.arlasready === false) {
      // setlasreadystate("none");
      // setarlasreadystate("flex");
      manageJobHistory();
      fetcharlasData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.arlasready]);

  return (
    <div style={{ display: "grid" }}>
      <div className="lasbox" style={{ display: lasreadystate }}>
        <div className="yxissvgtext"></div>
        <div className="yxissvg"></div>
        <div className="las_gammaRay"></div>
        <div className="las_deepResistivity"></div>
        <div className="las_neutron"></div>
        <div className="las_bulkDensity"></div>
        <div className="las_sonic"></div>
        <div className="las_caliper"></div>
        {listItem}
      </div>
      <div
        id="arlasboxerror"
        className="arlasboxerror"
        style={{ display: arlaserrorstate }}
      >
        {arlaserrortext}
      </div>
      <div className="arlasbox" style={{ display: arlasreadystate }}>
        <div className="aryxissvgtext"></div>
        <div className="aryxissvg"></div>
        <div className="arlasitem arlas_gammaRay"></div>
        <div className="arlasitem arlas_deepResistivity"></div>
        <div className="arlasitem arlas_neutron"></div>
        <div className="arlasitem arlas_bulkDensity"></div>
        <div className="arlasitem arlas_sonic"></div>
        <div className="arlasitem arlas_caliper"></div>
        {arlistItem}
      </div>
    </div>
  );
};

export default withAuth(ModalContainer);
