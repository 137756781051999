import React from "react";
import { FILE_PROCESSING_STATES } from "./createJobHelper";

import Loading from "../../components/Loading";

import done from "../../assets/images/icons/done.svg";
import error from "../../assets/images/icons/error.svg";

const Done = () => <img src={done} alt="done" title="Completed" />;
const Error = () => <img src={error} alt="error" title="Failed" />;

const Basin = props => {
  let icon = "...";
  if (props.status === FILE_PROCESSING_STATES.LOADING) {
    icon = <Loading />;
  } else if (props.status === FILE_PROCESSING_STATES.COMPLETED) {
    icon = <Done />;
  } else if (props.status === FILE_PROCESSING_STATES.FAILED) {
    icon = <Error />;
  }

  return <>{icon}</>;
};

export default Basin;
