import { useState, useEffect } from "react";
import config from "../../configs/appSettings";
import initialState from "./createJobInitialState";
import { validate, httpCall, FILE_PROCESSING_STATES } from "./createJobHelper";

const useCreateJobHook = props => {
  let statusTimer;
  let pollRetryCount = 24;
  let pollCount = 1;
  let pollItem = 24;
  const [formData, setFormData] = useState({
    basin: initialState.basin,
    lasFile: "",
    latitude: "",
    longitude: "",
    gammaRay: "",
    deepResistivity: "",
    neutron: "",
    bulkDensity: "",
    sonic: "",
    caliper: "",
    isBasinSupported: true,
    gammaRayInclude: true,
    deepResistivityInclude: true,
    neutronInclude: true,
    bulkDensityInclude: true,
    sonicInclude: true,
    caliperInclude: true
  });
  const [formErrors, setFormErrors] = useState({ show: false, errors: {} });

  const [mnemonicOptions, setMnemonicOptions] = useState(
    initialState.mnemonicOptions
  );

  const [mnemonicNotIncluded, setMnemonicNotIncluded] = useState(false);

  const [sinlgemnemonic, setsingleMnemonic] = useState(false);
  const [mnemonicGR, setmnemonicGR] = useState(false);
  const [mnemonicDPR, setmnemonicDPR] = useState(false);
  const [mnemonicNEU, setmnemonicNEU] = useState(false);
  const [mnemonicBDEN, setmnemonicBDEN] = useState(false);
  const [mnemonicCALI, setmnemonicCALI] = useState(false);
  const [footerElement, setfooterElement] = useState(false);
  const [basinOptions] = useState(initialState.basinOptions);
  const [converting, setConverting] = useState(false);
  const [viewArlas, setviewArlas] = useState(false);
  const [demoFlow, setdemoFlow] = useState(false);
  const [demoCurve, setdemoCurve] = useState(true);
  const [filterCurve, setfilterCurve] = useState(true);
  const [mapLatLong, setmapLatLong] = useState(null);
  const [convertionCount, setconvertionCount] = useState(0);

  let invalidFileStatus = FILE_PROCESSING_STATES.INVALIDLAS;
  const [filePaths, setFilePaths] = useState({
    uploadedFile: "",
    las: "",
    arlas: ""
  });

  const [errorModal, setErrorModal] = useState({
    show: false,
    error: "File size exceeded"
  });

  const [historyModal, setHistoryModal] = useState(false);
  const [convertModal, setConvertModal] = useState(false);
  // eslint-disable-next-line
  const [requestId, setRequestId] = useState("0");
  const [arlasPath, setARLasPath] = useState();

  const [enableSubmitButton, setEnableSubmitButton] = useState(true);
  const [enableDownloadButton, setEnableDownloadButton] = useState(true);

  const [basinMessage, setBasinMessage] = useState("");
  const [LasElementShow, setLasElementShow] = useState(false);

  const resetState = () => setBasinMessage("");

  const handleBasinChange = item => {
    let basinSmall = item.target.value.toLowerCase();
    setFormData({ ...formData, basin: basinSmall });

    setviewArlas(false);
  };

  const handleMnemonicChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setviewArlas(false);

    if (name === "sonic") {
      if (value !== "") {
        setsingleMnemonic(false);
      } else {
        setsingleMnemonic(true);
        disableSubmit();
      }
    }

    if (name === "gammaRay") {
      if (value !== "") {
        setmnemonicGR(false);
      } else {
        setmnemonicGR(true);
        disableSubmit();
      }
    }

    if (name === "deepResistivity") {
      if (value !== "") {
        setmnemonicDPR(false);
      } else {
        setmnemonicDPR(true);
        disableSubmit();
      }
    }

    if (name === "bulkDensity") {
      if (value !== "") {
        setmnemonicBDEN(false);
      } else {
        setmnemonicBDEN(true);
        disableSubmit();
      }
    }

    if (name === "neutron") {
      if (value !== "") {
        setmnemonicNEU(false);
      } else {
        setmnemonicNEU(true);
        disableSubmit();
      }
    }

    if (name === "caliper") {
      if (value !== "") {
        setmnemonicCALI(false);
      } else {
        setmnemonicCALI(true);
        disableSubmit();
      }
    }
  };

  const enableSubmit = () => setEnableSubmitButton(false);
  const disableSubmit = () => setEnableSubmitButton(true);

  async function getJobStatusApiCall() {
    let url = `${config.endpoints.arlas}/GetFileStatus`;
    try {
      const [, data] = await httpCall(
        url,
        { requestId: requestId },
        props.auth.getAccessToken
      );

      return data;
    } catch (err) {
      //alert(err);
      
    }
  }

  async function handleDownloadFile(e) {
    const response = await getJobStatusApiCall();
    if (response.status === "Completed") {
      window.open(response.arlasFilePath, "_blank");
    } else {
      console.log("response: ", response);
    }
  }

  async function getJobStatus() {
    clearTimeout(statusTimer);

    if (pollRetryCount > 0) {
      pollRetryCount--;
      const response = await getJobStatusApiCall();

      if (!response.status) {
        alert(response.error);
        enableSubmit();
        return;
      }

      switch (response.status) {
        case "InProgress":
          // Retry in 3 seconds
          statusTimer = setTimeout(() => {
            //console.log("checking for status of complete: ", new Date());
            getJobStatus();
          }, config.getJobStatusRetry);
          break;
        case "Completed":
          //updateFilePath("arlas", response.s3ArlasFilePath);
          setConverting(false);
          enableSubmit();
          setEnableDownloadButton(false);
          break;
        case "Failed":
          setConverting(null);
          setviewArlas(false);
          document.querySelector("#showFooter").click();
          enableSubmit();
          break;
        default:
          // failed
          //alert(response.error.error);
          alert(response.status)
          setConverting(null);
          setviewArlas(false);
          document.querySelector("#showFooter").click();
          enableSubmit();
      }
    } else {
      let totalTime = pollItem * config.getJobStatusRetry;
      let totalMinutes = totalTime / 60000;

      if (
        window.confirm(
          ` Conversion took longer than ${pollCount *
            totalMinutes} minutes. Continue waiting?`
        )
      ) {
        // Save it!
        pollRetryCount = pollRetryCount + pollItem;
        pollCount = pollCount + 1;

        getJobStatus();
      } else {
        // Do nothing!
        pollRetryCount = 0;
        pollCount = 1;
        setConverting(null);
        setviewArlas(false);
        document.querySelector("#showFooter").click();
        enableSubmit();
      }
      //alert("Conversion took longer than 2 minutes.");
      
    }

  }

  function preSelectMnemonics(mnemonics) {
    let toSelect = Object.keys(mnemonics)
      .map(item => {
        return {
          value: mnemonics[item].length ? mnemonics[item][0] : "",
          key: item,
          includeKey: item + "Include",
          includeValue: mnemonics[item].length >= 1
        };
      })
      .reduce((accumulator, current) => {
        const { key, value, includeKey, includeValue } = current;
        accumulator[key] = value;
        accumulator[includeKey] = includeValue;
        return accumulator;
      }, {});

    delete toSelect.other;
    setFormData(P => ({ ...P, ...toSelect }));
  }

  function handleIncludeCheckbox(event) {
    const { name, checked, id } = event.target;
    setFormData({ ...formData, [name]: checked });
    setviewArlas(false);
    if (checked === true) {
      if (formData[id] === "") {
        if (id === "sonic") {
          setsingleMnemonic(true);
        } else if (id === "gammaRay") {
          setmnemonicGR(true);
        } else if (id === "deepResistivity") {
          setmnemonicDPR(true);
        } else if (id === "bulkDensity") {
          setmnemonicBDEN(true);
        } else if (id === "neutron") {
          setmnemonicNEU(true);
        } else if (id === "caliper") {
          setmnemonicCALI(true);
        }
      }
    } else {
      if (formData[id] === "") {
        if (id === "sonic") {
          setsingleMnemonic(false);
        } else if (id === "gammaRay") {
          setmnemonicGR(false);
        } else if (id === "deepResistivity") {
          setmnemonicDPR(false);
        } else if (id === "bulkDensity") {
          setmnemonicBDEN(false);
        } else if (id === "neutron") {
          setmnemonicNEU(false);
        } else if (id === "caliper") {
          setmnemonicCALI(false);
        }
      }
    }
  }

  // History Modal
  function handleHistoryClick(state) {
    setHistoryModal(state);
  }

  function closeConvertModal(state) {
    setConvertModal(state);
  }

  // Error Modal
  function showErrorModal(error) {
    setErrorModal({ ...errorModal, show: true, error });
  }

  // Validate Error Modal
  function closeErrorModal() {
    setErrorModal({ ...errorModal, show: false, error: "" });
  }

  function closeValidationErrorModal() {
    const temp = { show: false, errors: {} };
    setFormErrors(temp);
  }

  // update S3 path
  function updateFilePath(key, path) {
    if (key === "uploadedFile") {
      setFormData(p => {
        p["lasFile"] = path;
        return p;
      });
    }

    setFilePaths(p => {
      return { ...p, [key]: path };
    });
  }

  async function convert(payload) {
    //update arlas path
    let bucketName = config.outputBucketName;
    let file = filePaths.las;
    // eslint-disable-next-line
    let outputFile = file.replace(/^.*[\\\/]/, "");
    outputFile = outputFile.split(".")[0];
    let arlasPath;
    // if (demoFlow === true) {

    //   setConvertModal(true);
    //   setEnableDownloadButton(false);
    //   let activeArlas = config.demoFlowLongLat.filter(function(e) {
    //     return filePaths.las === `s3://${e.inputPath}`;
    //   });
    //   payload.outputBucket = `s3://${activeArlas[0].outPath}`;
    //   //setARLasPath(undefined);
    //   // eslint-disable-next-line
    //   arlasPath = `s3://${bucketName}/demo/ARLAS_${outputFile}.` + "LAS";
    //   updateFilePath("arlas", arlasPath);
    //   setARLasPath(arlasPath);
    //} else {
    setConvertModal(true);

    const url = `${config.endpoints.arlas}/CreateJob`;

    if (demoFlow === true) {
      let activeArlas = config.demoFlowLongLat.filter(function(e) {
        return filePaths.las === `s3://${e.inputPath}`;
      });
      payload.outputBucket = `${activeArlas[0].outputPath}`;
      payload.demoFlow = "true";
    }

    try {
      const [response, data] = await httpCall(
        url,
        payload,
        props.auth.getAccessToken
      );

      //Reset allas path to reconvert
      setARLasPath(undefined);

      if (response.status === 200) {
        setRequestId(p => (p = data.requestId));

        //update arlas path
        // let bucketName = config.outputBucketName;
        // let file = filePaths.las;
        // // eslint-disable-next-line
        // let outputFile = file.replace(/^.*[\\\/]/, "");
        // outputFile = outputFile.split(".")[0];
        // let arlasPath;
        if (demoFlow === true) {
          setEnableDownloadButton(false);
          arlasPath = `s3://${bucketName}/demo/ARLAS_${outputFile}.LAS`;
        } else {
          arlasPath = `s3://${bucketName}/ARLAS_UI/ARLAS_${outputFile}_TGS_ARLAS.las`;
        }

        updateFilePath("arlas", arlasPath);
        setARLasPath(arlasPath);
      } else {
        alert("Something went wrong, see console");
      }
    } catch (err) {
      console.log("err: ", err);
      // alert("Something went wrong, see console2");
    }
    // }
  }

  // updateCoordinates
  function updateCoordinates(coordinates) {
    setFormData(P => ({ ...P, ...coordinates }));
    setFormErrors(p => ({ ...p, ...coordinates }));
  }

  // updateConverting
  function updateConverting() {
    setviewArlas(false);
  }

  // update Count
  function updateCount(count) {
    setconvertionCount(count);
  }

  // update RankMnemonics Options
  function updateRankMnemonics(mnemonics) {    
    preSelectMnemonics(mnemonics);
    setMnemonicOptions({ ...mnemonicOptions, ...mnemonics });
  }

  // updateBasin
  function updateBasin(basin) {
    let basinListArray = [
      "anadarko",
      "permian",
      "denver",
      "eagleford",
      "arkoma",
      "haynesville",
      "marcellus",
      "powderriver",
      "sanjoaquin",
      "sanjuan"
    ];
    let selectedBasinArray = basin.basins;
    let basinArray = [];
    if (basin !== "") {
      basinArray = basinListArray.filter(i => {
        return selectedBasinArray.includes(i);
      });
    }
    let value = "";
    let basinSupported = true;
    if (basin !== "") {
      if (
        basinArray[0] === "anadarko" ||
        basinArray[0] === "permian" ||
        basinArray[0] === "denver" ||
        basinArray[0] === "eagleford" ||
        basinArray[0] === "arkoma" ||
        basinArray[0] === "haynesville" ||
        basinArray[0] === "marcellus" ||
        basinArray[0] === "powderriver" ||
        basinArray[0] === "sanjoaquin" ||
        basinArray[0] === "sanjuan"
      ) {
        value = basinArray[0];
      } else if (basin.basins[0] === "UNKNOWN") {
        setBasinMessage(
          "The Well does not fit into an existing basin model. Please choose a nearby or geologically representative basin. May not yield best results."
        );
      } else {
        basinSupported = false;
        setBasinMessage(
          `Well is in ${formData.lasFile} Basin, which is not yet supported. Please choose another well`
        );
      }
    }
    setFormData(p => ({
      ...p,
      basin: value,
      isBasinSupported: basinSupported
    }));
  }

  // convert
  async function handleConvert() {
    const [errors, payload] = validate(formData, filePaths);
    if (Object.keys(errors).length === 0) {
      disableSubmit();
      setConverting(true);
      await convert(payload);
    } else {
      setFormErrors(p => {
        let temp = { ...p };
        temp.show = true;
        temp.errors = { ...errors };
        p = temp;
        return p;
      });
    }
  }

  async function handleArlasConvert() {
    setviewArlas(false);
    setConvertModal(true);
    setConverting(false);

    // const [errors, payload] = validate(formData, filePaths);

    // if (Object.keys(errors).length === 0) {
    //   enableSubmit();
    //   setConverting(false);
    //   await convert(payload);
    // } else {
    //   setFormErrors(p => {
    //     let temp = { ...p };
    //     temp.show = true;
    //     temp.errors = { ...errors };
    //     p = temp;
    //     return p;
    //   });
    // }
  }

  // useEffect(() => {
  //   if (requestId === "0") return;

  //   let timer = setTimeout(async () => {
  //     await getJobStatus();
  //   }, config.createJobDelay);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [requestId]);

  useEffect(() => {
    if (arlasPath === undefined) return;
    let timer = setTimeout(async () => {
      await getJobStatus();
    }, config.createJobDelay);

    return () => {
      clearTimeout(timer);
    };

    // if (demoFlow !== true) {
    //   let timer = setTimeout(async () => {
    //     await getJobStatus();
    //   }, config.createJobDelay);

    //   return () => {
    //     clearTimeout(timer);
    //   };
    // } else {
    //   setTimeout(function() {
    //     setConverting(false);
    //   }, 1000);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arlasPath]);

  useEffect(() => {
    let included =
      formData.gammaRayInclude ||
      formData.deepResistivityInclude ||
      formData.neutronInclude ||
      formData.bulkDensityInclude ||
      formData.sonicInclude;
    setMnemonicNotIncluded(!included);
  }, [
    formData.gammaRayInclude,
    formData.deepResistivityInclude,
    formData.neutronInclude,
    formData.bulkDensityInclude,
    formData.sonicInclude
  ]);

  useEffect(() => {
    const [errors] = validate(formData, filePaths);
    if (Object.keys(errors).length === 0) {
      if (document.querySelectorAll(".mnemonic-error").length === 0) {
        enableSubmit();
      }
    } else {
      disableSubmit();
    }
  }, [formData, filePaths]);

  useEffect(() => {
    if (demoCurve === false) {
      disableSubmit();
    } else {
      enableSubmit();
    }
  }, [demoCurve]);

  useEffect(() => {
    if (
      sinlgemnemonic === true ||
      mnemonicGR === true ||
      mnemonicDPR === true ||
      mnemonicNEU === true ||
      mnemonicBDEN === true ||
      mnemonicCALI === true
    ) {
      disableSubmit();
    }
    //else {
    //   enableSubmit();
    // }
  }, [
    sinlgemnemonic,
    mnemonicGR,
    mnemonicDPR,
    mnemonicNEU,
    mnemonicBDEN,
    mnemonicCALI
  ]);

  return {
    formData,
    formErrors,
    mnemonicOptions,
    mnemonicNotIncluded,
    sinlgemnemonic,
    mnemonicGR,
    mnemonicDPR,
    mnemonicNEU,
    mnemonicBDEN,
    mnemonicCALI,
    setsingleMnemonic,
    setmnemonicGR,
    setmnemonicDPR,
    setmnemonicNEU,
    setmnemonicBDEN,
    setmnemonicCALI,
    footerElement,
    setfooterElement,
    filePaths,
    basinOptions,
    basinMessage,
    errorModal,
    historyModal,
    convertModal,
    converting,
    viewArlas,
    setviewArlas,
    convertionCount,
    setconvertionCount,
    enableDownloadButton,
    enableSubmitButton,
    setEnableSubmitButton,
    resetState,
    handleBasinChange,
    handleIncludeCheckbox,
    showErrorModal,
    closeErrorModal,
    closeValidationErrorModal,
    handleMnemonicChange,
    updateFilePath,
    updateCoordinates,
    updateRankMnemonics,
    updateBasin,
    handleHistoryClick,
    closeConvertModal,
    handleConvert,
    handleArlasConvert,
    handleDownloadFile,
    updateConverting,
    updateCount,
    invalidFileStatus,
    requestId,
    demoFlow,
    setdemoFlow,
    demoCurve,
    setdemoCurve,
    filterCurve,
    setfilterCurve,
    mapLatLong,
    setmapLatLong,
    LasElementShow,
    setLasElementShow
  };
};

export { useCreateJobHook };
