import React from "react";
import { Modal } from "react-bootstrap";

const HistoryModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      centered
      dialogClassName="arlas-history-modal"
    >
      <Modal.Header>
        <h4 className="modal-title">History</h4>
        <button
          type="button"
          className="close-custom"
          onClick={() => onHide(false)}
        >
          X
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-sub-title">
          The LAS files have been converted to ARLAS
        </p>
        <div>
          <table width="100%" className="arlas-modal-data-grid">
            <tbody>
              <tr>
                <td width="20%">03/05/2019</td>
                <td width="75%">1dlfplxthn.LAS</td>
                <td width="5%">
                  <a href="/" className="arlastooltip">
                    <i className="ci ic-download"></i>
                    <span className="tooltipbody">Download</span>
                  </a>
                </td>
              </tr>
              <tr>
                <td width="20%">03/05/2019</td>
                <td width="75%">2dlfplxthn.LAS</td>
                <td width="5%">
                  <a href="/" className="arlastooltip">
                    <i className="ci ic-download"></i>
                    <span className="tooltipbody">Download</span>
                  </a>
                </td>
              </tr>
              <tr>
                <td width="20%">03/05/2019</td>
                <td width="75%">3dlfplxthn.LAS</td>
                <td width="5%">
                  <a href="/" className="arlastooltip">
                    <i className="ci ic-download"></i>
                    <span className="tooltipbody">Download</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HistoryModal;
