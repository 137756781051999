import React from "react";
import { loadModules } from "esri-loader";
import { setDefaultOptions } from "esri-loader";
import { loadCss } from "esri-loader";
import config from "../configs/appSettings";

// For Loading Map Version (3.31) from latest
loadCss("3.31");
setDefaultOptions({ version: "3.31" });
let activeLongtitude = null;
let activeLattitude = null;

class MapArea extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.mapbox = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.basinname !== this.props.basinname ||
      nextProps.longitude !== this.props.longitude ||
      nextProps.latitude !== this.props.latitude ||
      nextProps.tgsSample !== this.props.tgsSample ||
      nextProps.mapLatLong !== this.props.mapLatLong ||
      nextProps.setmapLatLong !== this.props.setmapLatLong ||
      nextProps.filterCurve !== this.props.filterCurve
    );
  }

  componentDidUpdate() {
    loadModules([
      "esri",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/graphic",
      "esri/geometry/Point",
      "esri/Color",
      "esri/symbols/SimpleFillSymbol",
      "esri/symbols/SimpleLineSymbol",
      "esri/layers/ArcGISDynamicMapServiceLayer",
      "esri/renderers/SimpleRenderer",
      "esri/layers/LayerDrawingOptions",
      "esri/layers/ArcGISTiledMapServiceLayer",
      "esri/urlUtils",
      "esri/InfoTemplate"
    ]).then(
      ([
        esri,
        SimpleMarkerSymbol,
        graphic,
        Point,
        Color,
        SimpleFillSymbol,
        SimpleLineSymbol,
        ArcGISDynamicMapServiceLayer,
        SimpleRenderer,
        LayerDrawingOptions,
        urlUtils,
        InfoTemplate
      ]) => {
        
        /* Add Well Start*/
        var sm = new SimpleMarkerSymbol({
          style: "circle",
          color: new Color([249, 160, 27, 0.8]),
          outline: {
            color: [0, 0, 0],
            width: 1 // points
          }
        });
        sm.setSize(15);

        var sm1 = new SimpleMarkerSymbol({
          style: "circle",
          color: new Color([170, 0, 113, 1]),
          outline: {
            color: [255, 255, 255],
            width: 1 // points
          }
        });
        sm1.setSize(15);

        let mapDemoFlow = this;
        if (this.props.tgsSample !== true) {
          if (this.props.latitude !== "") {
            if (this.props.latitude !== undefined) {
              var point = new Point(this.props.longitude, this.props.latitude);
              var grpic = new graphic(point, sm1);
              this.mapbox.graphics.clear();
              this.mapbox.graphics.add(grpic);
              this.mapbox.centerAndZoom(point);
            }
          }
        } else {
          setTimeout(function() {
            if (mapDemoFlow.props.latitude !== "") {
              if (mapDemoFlow.props.latitude !== undefined) {
                let demoLongLat = config.demoFlowLongLat;
                if (mapDemoFlow.props.filterCurve === true) {
                  mapDemoFlow.mapbox.graphics.clear();
                  let demopoint;
                  let demoGrpic;
                  let demoAttr;                  
                  for (let i = 0; i < demoLongLat.length; i++) {
                    demopoint = new Point(
                      demoLongLat[i].longtitude,
                      demoLongLat[i].latitude
                    );
                    demoAttr = {
                      longtitude: demoLongLat[i].longtitude,
                      latitude: demoLongLat[i].latitude
                    };
                    demoGrpic = new graphic(demopoint, sm, demoAttr);
                    mapDemoFlow.mapbox.centerAndZoom(demopoint);
                   
                    mapDemoFlow.mapbox.graphics.add(demoGrpic);
                  }
                }
              }
            }
          }, 1000);
          let hightlightDemoPoint;
          var hightlightDemoGrpic;
          mapDemoFlow.mapbox.graphics.on("click", function(e) {

            let demoLongLat = config.demoFlowLongLat;
            activeLongtitude = e.graphic.geometry.x;
            activeLattitude = e.graphic.geometry.y;            
            mapDemoFlow.mapbox.graphics.clear();
            let demopoint;
            let demoGrpic;
            let demoAttr;            
            for (let i = 0; i < demoLongLat.length; i++) {              
              demopoint = new Point(
                demoLongLat[i].longtitude,
                demoLongLat[i].latitude
              );
              demoAttr = {
                longtitude: demoLongLat[i].longtitude,
                latitude: demoLongLat[i].latitude
              };
              if (
                activeLongtitude === demoLongLat[i].longtitude &&
                activeLattitude === demoLongLat[i].latitude
              ) {
                demoGrpic = new graphic(demopoint, sm1, demoAttr);
              } else {
                demoGrpic = new graphic(demopoint, sm, demoAttr);
              }
              mapDemoFlow.mapbox.graphics.add(demoGrpic);
            }            
            let mapLongLatitude = e.graphic.geometry.x;
            hightlightDemoPoint = new Point(
              e.graphic.geometry.x,
              e.graphic.geometry.y
            );
            hightlightDemoGrpic = new graphic(hightlightDemoPoint, sm1);
            mapDemoFlow.mapbox.graphics.remove(e.graphic);
            mapDemoFlow.mapbox.graphics.add(hightlightDemoGrpic);
            mapDemoFlow.props.setmapLatLong(mapLongLatitude);
          });
        }
        /* Add Well End*/

        var layerID = "";
        var selectedID = "";
        var basinID = "";
        var strFilter = "";
        var layerDefAry = "";
        var reflayer = "";

        /* highlightBasin function Start */
        const highlightBasin = basinName => {          
          layerID = 0;
          selectedID = "selected-basinlayer";
          basinID = basinName;
          var basinNameUpperCase = basinID.toUpperCase();

          strFilter = "BASIN IN ('" + basinNameUpperCase + "')";
          layerDefAry = [];
          layerDefAry[layerID] = strFilter;

          reflayer = this.mapbox.getLayer(selectedID);
          reflayer.setLayerDefinitions(layerDefAry);
          reflayer.hide();
          reflayer.show();
        };

        /* highlightBasin function End */
        highlightBasin(this.props.basinname);
      }
    );
  }

  //page load
  componentDidMount() {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules([
      "esri/map",
      "esri/Color",
      "esri/symbols/SimpleFillSymbol",
      "esri/symbols/SimpleLineSymbol",
      "esri/layers/ArcGISDynamicMapServiceLayer",
      "esri/urlUtils",
      "esri/renderers/SimpleRenderer",
      "esri/layers/LayerDrawingOptions",
      "esri/layers/ArcGISTiledMapServiceLayer"
    ])
      .then(
        ([
          Map,
          Color,
          SimpleFillSymbol,
          SimpleLineSymbol,
          ArcGISDynamicMapServiceLayer,
          urlUtils,
          SimpleRenderer,
          LayerDrawingOptions,
          ArcGISTiledMapServiceLayer
        ]) => {
          // create map with the given options at a DOM node w/ id 'mapNode'
          this.mapbox = new Map(this.mapRef.current, {
            basemap: "gray",
            //center: [-94.08761370000047, 29.538211358412124],
            center: [37.09024, -95.712891],
            zoom: 4,
            minZoom: 3,
            maxZoom: 15
          });

          urlUtils.addProxyRule({
            urlPrefix:
              "https://gisprod01.tgsr360.com/arcgis/rest/services/DataLake/WellsMetaData_P1/MapServer",
            proxyUrl: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx"
          });

          urlUtils.addProxyRule({
            urlPrefix:
              "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Premium/TGS_Formations/MapServer",
            proxyUrl: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx"
          });

          urlUtils.addProxyRule({
            urlPrefix:
              "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Standard/WellLocation/MapServer",
            proxyUrl: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx"
          });

          urlUtils.addProxyRule({
            urlPrefix:
              "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Standard/WellLocationCacheMultiLayer/MapServer",
            proxyUrl: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx"
          });

          urlUtils.addProxyRule({
            urlPrefix:
              "https://gisprod01.tgsr360.com/arcgis/rest/services/Private_TGS/BasinTrainPath/MapServer",
            proxyUrl: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx"
          });

          const addWellLayer = () => {
            var wellShapeUrl =
              "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Standard/WellLocation/MapServer";
            var wellLayerOptions = {
              id: "wellslayer",
              opacity: 1,
              showAttribution: false
            };
            var wellLayer = new ArcGISDynamicMapServiceLayer(
              wellShapeUrl,
              wellLayerOptions
            );
            wellLayer.setVisibleLayers([0]);
            wellLayer.setVisibility(true);
            this.mapbox.addLayer(wellLayer);
          };

          addWellLayer();
          const addWellCacheLayer = () => {
            var wellShapeUrl =
              "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Standard/WellLocationCacheMultiLayer/MapServer";
            var wellLayer = new ArcGISTiledMapServiceLayer(wellShapeUrl);
            this.mapbox.addLayer(wellLayer);
            wellLayer.show();
          };
          addWellCacheLayer();
          /* Add Well Layer End */

          var basinLayer;
          const addBasinLayer = () => {
            var basinShapeUrl =
              // "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Premium/TGS_Formations/MapServer";
              "https://gisprod01.tgsr360.com/arcgis/rest/services/Private_TGS/BasinTrainPath/MapServer";

            var basinLayerOptions = {
              id: "basinlayer",
              opacity: 0.3,
              showAttribution: false
            };
            basinLayer = new ArcGISDynamicMapServiceLayer(
              basinShapeUrl,
              basinLayerOptions
            );
            var strFilter = "NAME IN ('PERMIAN')";
            var layerDefAry = [];
            layerDefAry[0] = strFilter;
            var boundaryProjectSymbol = new SimpleFillSymbol(
              SimpleFillSymbol.STYLE_SOLID,
              new SimpleLineSymbol(
                SimpleLineSymbol.STYLE_SOLID,
                new Color([0, 0, 0]),
                2
              ),
              new Color([0, 255, 255, 1])
            );

            var layerDrawingOptionPolygon = new LayerDrawingOptions();
            layerDrawingOptionPolygon.renderer = new SimpleRenderer(
              boundaryProjectSymbol
            );
            var optionsArray = [];
            optionsArray[0] = layerDrawingOptionPolygon;
            basinLayer.setLayerDrawingOptions(optionsArray);
            basinLayer.setVisibleLayers([0]);
            basinLayer.setVisibility(true);
            this.mapbox.addLayer(basinLayer);
          };
          addBasinLayer();
          /* addBasinLayer function End */

          /* addSelectedBasinLayer function start */
          var selectedBasinLayer;
          const addSelectedBasinLayer = () => {
            var basinShapeUrl =
              // "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Premium/TGS_Formations/MapServer";
              "https://gisprod01.tgsr360.com/arcgis/rest/services/Private_TGS/BasinTrainPath/MapServer";

            var basinLayerOptions = {
              id: "selected-basinlayer",
              opacity: 0.6,
              showAttribution: false
            };
            selectedBasinLayer = new ArcGISDynamicMapServiceLayer(
              basinShapeUrl,
              basinLayerOptions
            );
            var strFilter = "NAME IN ('PERMIAN')";
            var layerDefAry = [];
            layerDefAry[0] = strFilter;
            var boundaryProjectSymbol = new SimpleFillSymbol(
              SimpleFillSymbol.STYLE_SOLID,
              new SimpleLineSymbol(
                SimpleLineSymbol.STYLE_SOLID,
                new Color([0, 0, 0]),
                2
              ),

              new Color([255, 255, 0, 1])
            );

            var layerDrawingOptionPolygon = new LayerDrawingOptions();
            layerDrawingOptionPolygon.renderer = new SimpleRenderer(
              boundaryProjectSymbol
            );
            var optionsArray = [];
            optionsArray[0] = layerDrawingOptionPolygon;
            selectedBasinLayer.setLayerDrawingOptions(optionsArray);
            selectedBasinLayer.setVisibleLayers([0]);
            selectedBasinLayer.setVisibility(true);
            this.mapbox.addLayer(selectedBasinLayer);
          };
          /* addSelectedBasinLayer function End */
          addSelectedBasinLayer();
        }
      )
      .catch(err => {
        console.error(err);
      });
  }

  render() {
    return <div className="webmap mapsection" ref={this.mapRef} />;
  }
}

export default MapArea;
