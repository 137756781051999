import React, { useEffect } from "react";
// eslint-disable-next-line
const Basin = props => {
  useEffect(() => {
    var basinNameUpper = props.basinSelected;
    var basinNameCamel;
    if (basinNameUpper === "anadarko") {
      basinNameCamel = "Anadarko";
    } else if (basinNameUpper === "permian") {
      basinNameCamel = "Permian";
    } else if (basinNameUpper === "denver") {
      basinNameCamel = "Denver";
    } else if (basinNameUpper === "eagleford") {
      basinNameCamel = "EagleFord";
    } else if (basinNameUpper === "arkoma") {
      basinNameCamel = "Arkoma";
    } else if (basinNameUpper === "haynesville") {
      basinNameCamel = "Haynesville";
    } else if (basinNameUpper === "marcellus") {
      basinNameCamel = "Marcellus";
    } else if (basinNameUpper === "powderriver") {
      basinNameCamel = "PowderRiver";
    } else if (basinNameUpper === "sanjoaquin") {
      basinNameCamel = "SanJoaquin";
    } else if (basinNameUpper === "sanjuan") {
      basinNameCamel = "SanJuan";
    } else {
      basinNameCamel = "Permian";
    }
    document.querySelector("#girdArea").value = basinNameCamel;
    // eslint-disable-next-line
  }, [props.basinSelected]);
  return (
    <div>
      <select
        id="girdArea"
        style={{ marginTop: "20px", width: "150px" }}
        className="arlas-select"
        onChange={props.handleBasinChange}
      >
        <option value="Anadarko">Anadarko</option>
        <option value="Arkoma">Arkoma</option>
        <option value="Denver">Denver</option>
        <option value="EagleFord">EagleFord</option>
        <option value="Haynesville">Haynesville</option>
        <option value="Marcellus">Marcellus</option>
        <option value="Permian">Permian</option>
        <option value="PowderRiver">PowderRiver</option>
        <option value="SanJoaquin">SanJoaquin</option>
        <option value="SanJuan">SanJuan</option>
      </select>
    </div>
  );
};

export default Basin;
