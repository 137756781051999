export default {
  name: "default",
  oidc: {
    clientId: "0oa2hgl0owqinPeDA357",
    oktaDomain: "https://tgsnopec.okta.com",
    issuer: "https://login.tgsnopec.com/oauth2/aus2w3la8yUN7Nras357",
    redirectUri: "http://localhost:8000/implicit/callback",
    scope: [
      "openid",
      "profile",
      "email",
      "address",
      "phone",
      "offline_access",
      "ARLAS-API-Scope"
    ],
    allowedGroups: [
      "arlas-service.tgs.ai",
      "AP-Arlas-Service",
      "NA-Arlas-Service",
      "EU-Arlas-Service"
    ]
  },
  endpoints: {
    arlas: "https://c9v9dxz2a6.execute-api.us-east-1.amazonaws.com/Prod/",
    arlasCommon: "https://glyr7oibf4.execute-api.us-east-1.amazonaws.com/Prod/"
  },
  createJobDelay: 20000,
  getJobStatusRetry: 5000,
  maximumFileSize: 26214400, // 25 MB
  mnemonicNonSelectionMessage: "Please select at least one mnemonic.",
  outputBucketName: "prod-tgs-ai-arlas-output-data",
  demoFlowLongLat: [
    {
      longtitude: -96.7814775521684,
      latitude: 33.77780424968382,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42181301490000_3064153_00086H777491.LAS",
      name: "US42181301490000_3064153_00086H777491.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42181301490000_3064153_00086H777491.LAS",
        demoRankMnemonics:{"caliper":["CALS"],"gammaRay":["GRS"],"other":["CALS","DT","GRS","ILD","ILM","LL8","SPHI","SPR"],"sonic":["SPHI","DT"],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -97.91422404462749,
      latitude: 35.33024898837475,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/A2DSUB_US35051221230000_3064153_00063H1211190.LAS",
      name: "A2DSUB_US35051221230000_3064153_00063H1211190.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_A2DSUB_US35051221230000_3064153_00063H1211190.LAS",
        demoRankMnemonics:{"caliper":["CALX"],"gammaRay":["GRR"],"other":["CALX","CILD","DPHI_LS","DRHO","GRR","ILD","ILM","LL3","NPHI_LS","PEF","RHOB","SPR"],"sonic":[],"neutron":["NPHI_LS"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -97.88735350544091,
      latitude: 35.33788902078634,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/A2DSUB_US35051218090000_3064153_00061H1211187.LAS",
      name: "A2DSUB_US35051218090000_3064153_00061H1211187.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_A2DSUB_US35051218090000_3064153_00061H1211187.LAS",
        demoRankMnemonics:{"caliper":["CALX","CALY"],"gammaRay":["GRR"],"other":["CALX","CALY","CDL_LS","CILD","CNS_LS","DRHO","GRR","RHOB","RILD","RILM","RILS","SPR"],"sonic":[],"neutron":["CNS_LS"],"bulkDensity":["RHOB"],"deepResistivity":["RILD"]}
    },
    {
      longtitude: -99.02262426391087,
      latitude: 35.906694570896,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/A2DSUB_US35043223690000_3064153_00059H800074.LAS",
      name: "A2DSUB_US35043223690000_3064153_00059H800074.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_A2DSUB_US35043223690000_3064153_00059H800074.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRR"],"other":["CALD","CILD","DCOR","DPHI","DT","GRR","ILD","ILM","NPHI","PE","RHOB","SFLA","SFLU","SPR"],"sonic":["DT"],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -98.96769306487798,
      latitude: 35.93233407732262,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US35043207470000_3064153_00057H799066.LAS",
      name: "US35043207470000_3064153_00057H799066.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US35043207470000_3064153_00057H799066.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","DPHI","DRHO","GRD","ILD","ILM","LL8","NPHI","RHOB","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -98.66179395949369,
      latitude: 36.02256131996551,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US35043206060000_3064153_00055H803945.LAS",
      name: "US35043206060000_3064153_00055H803945.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US35043206060000_3064153_00055H803945.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","CORR","DPHI","GRD","ILD","ILM","RHOB","SFL","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -99.32685336363612,
      latitude: 35.57883861517173,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US35039205400000_3064153_00053H669957.LAS",
      name: "US35039205400000_3064153_00053H669957.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US35039205400000_3064153_00053H669957.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRR"],"other":["CALD","CILD","DPHI","DRHO","GRR","ILD","ILM","NPHI","RHOB","SFLA","SFLU","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -98.93598717692964,
      latitude: 35.69990824453852,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US35039203720000_3064153_00051H674349.LAS",
      name: "US35039203720000_3064153_00051H674349.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US35039203720000_3064153_00051H674349.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRR"],"other":["CALD","CILD","DPHI","DRHO","GRR","ILD","ILM","NPHI","RHOB","SFLU","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -93.95703207602503,
      latitude: 35.276850824485024,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US03047113640000_3064153_00001H666900.LAS",
      name: "US03047113640000_3064153_00001H666900.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US03047113640000_3064153_00001H666900.LAS",
        demoRankMnemonics:{"caliper":["HCAL"],"gammaRay":["GRR"],"other":["AT10","AT30","AT90","DPHZ","GRR","HCAL","HDRA","NPOR","PEFZ","RHOZ","SPR"],"sonic":[],"neutron":["NPOR"],"bulkDensity":["RHOZ"],"deepResistivity":["AT90"]}
    },
    {
      longtitude: -93.9272016777876,
      latitude: 35.21498172727307,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US03083112620000_3064153_00003H1223969.LAS",
      name: "US03083112620000_3064153_00003H1223969.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US03083112620000_3064153_00003H1223969.LAS",
        demoRankMnemonics:{"caliper":["HCALD"],"gammaRay":["GRR"],"other":["AT10","AT30","AT90","DPHZ_LS","ENPH_LS","GRR","HCALD","HDRA","PEFZ","RHOZ"],"sonic":[],"neutron":["ENPH_LS"],"bulkDensity":["RHOZ"],"deepResistivity":["AT90"]}
    },
    {
      longtitude: -104.45121073544706,
      latitude: 40.3577710578333,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US05123352600000_3064153_00027H4573924.LAS",
      name: "US05123352600000_3064153_00027H4573924.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US05123352600000_3064153_00027H4573924.LAS",
        demoRankMnemonics:{"caliper":["CALYR"],"gammaRay":["GRC","GRR"],"other":["CALYR","GRC","GRR","RES30","RES60","RT"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["RT","RES60"]}
    },
    {
      longtitude: -97.49714316344209,
      latitude: 33.505779132979846,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42337340580000_3064153_00094H1231022.LAS",
      name: "US42337340580000_3064153_00094H1231022.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42337340580000_3064153_00094H1231022.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRD"],"other":["CNPOR","GRD"],"sonic":[],"neutron":["CNPOR"],"bulkDensity":[],"deepResistivity":[]}
    },
    {
      longtitude: -94.91515233608483,
      latitude: 35.10602768180509,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US35079202360000_3064153_00065H543213.LAS",
      name: "US35079202360000_3064153_00065H543213.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US35079202360000_3064153_00065H543213.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","CORR","DPHI","GRD","ILD","ILM","LL8","NPHI","RHOB","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -97.49076311898327,
      latitude: 34.4027169266779,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US35019239700000_3064153_00049H691033.LAS",
      name: "US35019239700000_3064153_00049H691033.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US35019239700000_3064153_00049H691033.LAS",
        demoRankMnemonics: {"caliper":["CALD"],"gammaRay":["GRR"],"other":["CALD","CILD","DPHI","DRHO","GRR","ILD","ILM","NPHI","PEF","RHOB","SFLA","SFLU","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -97.23640395795546,
      latitude: 34.179193814520104,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US35019230490000_3064153_00047H844919.LAS",
      name: "US35019230490000_3064153_00047H844919.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US35019230490000_3064153_00047H844919.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRR"],"other":["CALD","CNC","CORR","DEN","GRR","PORD","RFOC","RILD","RILM","SPR"],"sonic":[],"neutron":["CNC"],"bulkDensity":["DEN"],"deepResistivity":["RILD"]}
    },
    {
      longtitude: -104.48041725106745,
      latitude: 41.753432375568586,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49015200700000_3064153_00144H636951.LAS",
      name: "US49015200700000_3064153_00144H636951.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49015200700000_3064153_00144H636951.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["ASN","CALD","CILD","CORR","DPHI","GRD","ILD","RHOB","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -104.50051792836429,
      latitude: 41.5070869500146,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49021201020000_3064153_00148H636614.LAS",
      name: "US49021201020000_3064153_00148H636614.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49021201020000_3064153_00148H636614.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["ASN","CALD","CILD","CORR","DPHI","GRD","ILD","RHOB","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -104.78272786031047,
      latitude: 39.82538629993606,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US05001065280000_3064153_00019H1254110.LAS",
      name: "US05001065280000_3064153_00019H1254110.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US05001065280000_3064153_00019H1254110.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["ASN","CALD","CILD","CORR","DPHI_SS","GRD","ILD","RHOB","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -104.78292745367138,
      latitude: 39.883705390873,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US05001073820000_3064153_00021H569067.LAS",
      name: "US05001073820000_3064153_00021H569067.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US05001073820000_3064153_00021H569067.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["ASN","CALD","CILD","CORR","DPHI","GRD","ILD","RHOB","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -104.46871117901091,
      latitude: 40.35526100782729,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US05123370860000_3064153_00029H4558109.LAS",
      name: "US05123370860000_3064153_00029H4558109.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US05123370860000_3064153_00029H4558109.LAS",
        demoRankMnemonics:{"caliper":["CALXD"],"gammaRay":["GR","GRD"],"other":["CALXD","DCOR","DPOR_LS","GR","GRD","NPOR_LS","PE","RES30","RES40","RHOB","RT"],"sonic":[],"neutron":["NPOR_LS"],"bulkDensity":["RHOB"],"deepResistivity":["RT"]}
    },
    {
      longtitude: -104.82842793388669,
      latitude: 40.12210327216686,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US05123156210000_3064153_00025H657206.LAS",
      name: "US05123156210000_3064153_00025H657206.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US05123156210000_3064153_00025H657206.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRR"],"other":["CALD","CILD","DPHI","DRHO","GRR","ILD","ILM","NPHI","RHOB","SFLU","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -104.78352789543372,
      latitude: 39.82506630398558,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US05031065280000_3064153_00023H557958.LAS",
      name: "US05031065280000_3064153_00023H557958.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US05031065280000_3064153_00023H557958.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["ASN","CALD","CILD","CORR","DPHI_SS","GRD","ILD","RHOB","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -98.45461236774436,
      latitude: 28.444281759586463,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42311320980000_3064153_00090H670347.LAS",
      name: "US42311320980000_3064153_00090H670347.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42311320980000_3064153_00090H670347.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["CILD","GRR","RDLI","RFLF","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["RDLI"]}
    },
    {
      longtitude: -98.50027697245487,
      latitude: 26.582878247429637,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42427337360000_3064153_00108H773514.LAS",
      name: "US42427337360000_3064153_00108H773514.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42427337360000_3064153_00108H773514.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["AF10","AF60","AFCO","GRR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["AF60"]}
    },
    {
      longtitude: -99.06754393202891,
      latitude: 27.759305371721577,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42479347420000_3064153_00112H797704.LAS",
      name: "US42479347420000_3064153_00112H797704.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42479347420000_3064153_00112H797704.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["CILD","GRR","ILD","LL3","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -98.91663993060828,
      latitude: 27.93465251237775,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42479364180000_3064153_00114H785992.LAS",
      name: "US42479364180000_3064153_00114H785992.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42479364180000_3064153_00114H785992.LAS",
        demoRankMnemonics:{"caliper":["DCAL"],"gammaRay":["GRR"],"other":["ASFL","CILD","DCAL","DPHI","DRHO","GRR","ILD","ILM","NPHI","SFLU","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -98.92050152818278,
      latitude: 27.443547491671925,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42479384010000_3064153_00118H789943.LAS",
      name: "US42479384010000_3064153_00118H789943.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42479384010000_3064153_00118H789943.LAS",
        demoRankMnemonics:{"caliper":["CALX"],"gammaRay":["GRR"],"other":["CALX","CNC","GRR","M1R1","M1R3","M1R6","PORZ","SPR","ZCOR"],"sonic":[],"neutron":["CNC"],"bulkDensity":[],"deepResistivity":["M1R6"]}
    },
    {
      longtitude: -98.82278832038736,
      latitude: 27.517207965773867,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42479365840000_3064153_00116H788396.LAS",
      name: "US42479365840000_3064153_00116H788396.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42479365840000_3064153_00116H788396.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["AILM","ARFL","CILD","GRR","ILD","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -98.1335850594073,
      latitude: 28.98759017172033,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42493309410000_3064153_00120H688082.LAS",
      name: "US42493309410000_3064153_00120H688082.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42493309410000_3064153_00120H688082.LAS",
        demoRankMnemonics:{"caliper":["CALR"],"gammaRay":["GRR"],"other":["CALR","DPHI","GRR","ILD","ILM","SFL","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -97.94213098846089,
      latitude: 29.120456010111713,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42493321990000_3064153_00122H670301.LAS",
      name: "US42493321990000_3064153_00122H670301.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42493321990000_3064153_00122H670301.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["AGRD","CILD","GRR","ILD","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -94.41528964812662,
      latitude: 32.19600048828109,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42365340500000_3064153_00096H801311.LAS",
      name: "US42365340500000_3064153_00096H801311.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42365340500000_3064153_00096H801311.LAS",
        demoRankMnemonics:{"caliper":["DCAL"],"gammaRay":["GRR"],"other":["AO10","AO30","AO90","DCAL","DPHZ","GRR","HDRA","NPOR","PEFZ","SPR"],"sonic":[],"neutron":["NPOR"],"bulkDensity":[],"deepResistivity":["AO90"]}
    },
    {
      longtitude: -94.340839,
      latitude: 32.211258,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42365351710000_3064153_00098H810307.LAS",
      name: "US42365351710000_3064153_00098H810307.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42365351710000_3064153_00098H810307.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["GRR","ILD","ILM","SFL","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -94.35686670972738,
      latitude: 32.39351455815387,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42203329090000_3064153_00088H663328.LAS",
      name: "US42203329090000_3064153_00088H663328.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42203329090000_3064153_00088H663328.LAS",
        demoRankMnemonics:{"caliper":["DCAL"],"gammaRay":["GRR"],"other":["CORR","DCAL","DPOR","GRR","ILD","ILM","NPOR","PE","SFL","SPR"],"sonic":[],"neutron":["NPOR"],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -94.36478529250962,
      latitude: 32.10687002006644,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42365352690000_3064153_00100H810306.LAS",
      name: "US42365352690000_3064153_00100H810306.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42365352690000_3064153_00100H810306.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["AF10","AF30","AF90","GRR","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["AF90"]}
    },
    {
      longtitude: -94.06559802220558,
      latitude: 32.250830174673,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42365355540000_3064153_00102H807010.LAS",
      name: "US42365355540000_3064153_00102H807010.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42365355540000_3064153_00102H807010.LAS",
      demoRankMnemonics:{"caliper":["DCAL"],"gammaRay":["GRR"],"other":["DCAL","DCOR","DPOR","GRR","ILD","ILM","NPOR","PE","SFE","SPR"],"sonic":[],"neutron":["NPOR"],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -96.25000093031818,
      latitude: 31.71698794840873,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42161331650000_3064153_00082H1135165.LAS",
      name: "US42161331650000_3064153_00082H1135165.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42161331650000_3064153_00082H1135165.LAS",
        demoRankMnemonics:{"caliper":["DCAL"],"gammaRay":["GRR"],"other":["DCAL","DCOR","DFL","DPOR","GRR","HDRS","HMRS","NPOR","PE","SPR"],"sonic":[],"neutron":["NPOR"],"bulkDensity":[],"deepResistivity":["HDRS"]}
    },
    {
      longtitude: -94.42306931958628,
      latitude: 32.25226868872543,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42365356770000_3064153_00104H807011.LAS",
      name: "US42365356770000_3064153_00104H807011.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42365356770000_3064153_00104H807011.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["GRR","ILD","ILM","SFE","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -94.41140904327979,
      latitude: 32.24133908436792,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42365357310000_3064153_00106H767602.LAS",
      name: "US42365357310000_3064153_00106H767602.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42365357310000_3064153_00106H767602.LAS",
        demoRankMnemonics: {"caliper":["CALR"],"gammaRay":["GRR"],"other":["AT10","AT30","AT90","CALR","DPHI","GRR","HDRA","NPHI","PEFZ","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":[],"deepResistivity":["AT90"]}
    },
    {
      longtitude: -76.89002193945184,
      latitude: 42.238584844915735,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US31015229750000_3064153_00043H1006487.LAS",
      name: "US31015229750000_3064153_00043H1006487.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US31015229750000_3064153_00043H1006487.LAS",
        demoRankMnemonics:{"caliper":["HCAL"],"gammaRay":["GRD"],"other":["GRD","HCAL","HDRA","NPHI","PEFZ","RHOZ","RLA3","RLA5","RXOZ"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOZ"],"deepResistivity":["RLA5"]}
    },
    {
      longtitude: -81.10764107359222,
      latitude: 40.65772095982876,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US34019202860000_3064153_00045H1081417.LAS",
      name: "US34019202860000_3064153_00045H1081417.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US34019202860000_3064153_00045H1081417.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","CORR","DPHI","GRD","RHOB"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":[]}
    },
    {
      longtitude: -79.19641370587038,
      latitude: 40.23622283036181,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US37129240850000_3064153_00072H625501.LAS",
      name: "US37129240850000_3064153_00072H625501.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US37129240850000_3064153_00072H625501.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","DPHI","DRHO","ENPH","GRD","IDPH","IMPH","PEF","RHOB"],"sonic":[],"neutron":["ENPH"],"bulkDensity":["RHOB"],"deepResistivity":["IDPH"]}
    },
    {
      longtitude: -79.3079252941075,
      latitude: 39.9863352340318,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US37111201810000_3064153_00070H934695.LAS",
      name: "US37111201810000_3064153_00070H934695.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US37111201810000_3064153_00070H934695.LAS",
        demoRankMnemonics:{"caliper":["CALX"],"gammaRay":["GRD"],"other":["CALX","CDL","CNS","GRD","HDRA","PE","RHOB"],"sonic":[],"neutron":["CNS"],"bulkDensity":["RHOB"],"deepResistivity":[]}
    },
    {
      longtitude: -78.81617587717027,
      latitude: 40.5096807350702,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US37021200150000_3064153_00068H893499.LAS",
      name: "US37021200150000_3064153_00068H893499.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US37021200150000_3064153_00068H893499.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","CILD","CORR","DPHI","GRD","ILD","RHOB"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -79.29562496958441,
      latitude: 39.04444912883949,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US47023000250000_3064153_00126H955995.LAS",
      name: "US47023000250000_3064153_00126H955995.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US47023000250000_3064153_00126H955995.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","DPHI","DRHO","GRD","ILD","ILM","PEF","RHOB","SFLU","SNP"],"sonic":[],"neutron":["SNP"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -79.4412924542305,
      latitude: 39.0292491288268,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US47093000490000_3064153_00128H956077.LAS",
      name: "US47093000490000_3064153_00128H956077.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US47093000490000_3064153_00128H956077.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD","GRN"],"other":["CALD","CIL","CORR","DPHI","GRD","GRN","IL","NEUT","RHOB"],"sonic":[],"neutron":["NEUT"],"bulkDensity":["RHOB"],"deepResistivity":["IL"]}
    },
    {
      longtitude: -102.05731281568822,
      latitude: 31.39075134024226,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42461348300000_3064153_00110H1247457.LAS",
      name: "US42461348300000_3064153_00110H1247457.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42461348300000_3064153_00110H1247457.LAS",
        demoRankMnemonics: {"caliper":[],"gammaRay":["GRD"],"other":["GRD","NPOR"],"sonic":[],"neutron":["NPOR"],"bulkDensity":[],"deepResistivity":[]}
    },
    {
      longtitude: -102.43162103152136,
      latitude: 31.469678080676175,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42103325720000_3064153_00074H658318.LAS",
      name: "US42103325720000_3064153_00074H658318.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42103325720000_3064153_00074H658318.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","DCOR","DPHI","GRD","LLD","LLS","NPHI","RHOB"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["LLD"]}
    },
    {
      longtitude: -102.15881782646566,
      latitude: 32.0360812939001,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42329377150000_3064153_00092H5069942.LAS",
      name: "US42329377150000_3064153_00092H5069942.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42329377150000_3064153_00092H5069942.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRD"],"other":["GRD","NPHI_LS"],"sonic":[],"neutron":["NPHI_LS"],"bulkDensity":[],"deepResistivity":[]}
    },
    {
      longtitude: -103.88220680194343,
      latitude: 32.837267611518826,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US30015370430000_3064153_00031H1195108.LAS",
      name: "US30015370430000_3064153_00031H1195108.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US30015370430000_3064153_00031H1195108.LAS",
        demoRankMnemonics:{"caliper":["HCALD"],"gammaRay":["HSGRD"],"other":["DPHZ_LS","HCALD","HDRA","HSGRD","PEFZ","RHOZ","RLA3","RLA5","RXOZ","TNPH_LS"],"sonic":[],"neutron":["TNPH_LS"],"bulkDensity":["RHOZ"],"deepResistivity":["RLA5"]}
    },
    {
      longtitude: -101.52919955158183,
      latitude: 31.915151638973057,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42173349820000_3064153_00084H5184271.LAS",
      name: "US42173349820000_3064153_00084H5184271.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42173349820000_3064153_00084H5184271.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["SGRDD"],"other":["CALD","DPHZ_LS","HDRA","PEFZ","RHOZ","SGRDD","TNPH_LS"],"sonic":[],"neutron":["TNPH_LS"],"bulkDensity":["RHOZ"],"deepResistivity":[]}
    },
    {
      longtitude: -102.720234626053,
      latitude: 32.08095223801035,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42135379640000_3064153_00080H681069.LAS",
      name: "US42135379640000_3064153_00080H681069.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42135379640000_3064153_00080H681069.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","DCOR","DPHI","GRD","LLD","LLS","MSFL","NPHI","PE","RHOB"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["LLD"]}
    },
    {
      longtitude: -102.60112653608547,
      latitude: 31.891141793303664,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42135348140000_3064153_00078H693622.LAS",
      name: "US42135348140000_3064153_00078H693622.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42135348140000_3064153_00078H693622.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","CNC","CORR","DEN","GRD","LLD","LLS","PORD"],"sonic":[],"neutron":["CNC"],"bulkDensity":["DEN"],"deepResistivity":["LLD"]}
    },
    {
      longtitude: -102.42472096323304,
      latitude: 31.747059360594175,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US42135303340000_3064153_00076H663297.LAS",
      name: "US42135303340000_3064153_00076H663297.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US42135303340000_3064153_00076H663297.LAS",
        demoRankMnemonics:{"caliper":["CALI"],"gammaRay":["GR"],"other":["CALI","GR","GUAR","SNP"],"sonic":[],"neutron":["SNP"],"bulkDensity":[],"deepResistivity":[]}
    },
    {
      longtitude: -105.38374049878905,
      latitude: 43.4268184772069,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49009200810000_3064153_00132H468605.LAS",
      name: "US49009200810000_3064153_00132H468605.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49009200810000_3064153_00132H468605.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","CILD","CORR","DPHI","GRD","ILD","ILM","LL8","RHOB","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -105.42108066950496,
      latitude: 43.99848096034177,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49005251070000_3064153_00130H812436.LAS",
      name: "US49005251070000_3064153_00130H812436.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49005251070000_3064153_00130H812436.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRR","SGRD"],"other":["AC","ASGR","CALD","CIL","CORR","DPHI","GRR","IL","ILD","ILM","RHOB","SGRD","SPR"],"sonic":["AC"],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD","IL"]}
    },
    {
      longtitude: -105.4260420746872,
      latitude: 43.4312882163504,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49009213210000_3064153_00138H466190.LAS",
      name: "US49009213210000_3064153_00138H466190.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49009213210000_3064153_00138H466190.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["ASFL","CALD","CILD","DPHI_SS","DRHO","GRD","ILD","RHOB","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -105.32523827516158,
      latitude: 43.4108387395504,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49009221550000_3064153_00140H387809.LAS",
      name: "US49009221550000_3064153_00140H387809.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49009221550000_3064153_00140H387809.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRR","GRD","GRS"],"other":["ASGUARD","CALD","CILD","CORR","DPOR_SS","DT","GRD","GRR","GRS","ILD","ILM","NPOR_SS","RHOB","SGUARD","SPHI_SS","SPR"],"sonic":["DT","SPHI_SS"],"neutron":["NPOR_SS"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -105.02253534623087,
      latitude: 44.55835299977425,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49011213420000_3064153_00142H4933882.LAS",
      name: "US49011213420000_3064153_00142H4933882.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49011213420000_3064153_00142H4933882.LAS",
        demoRankMnemonics:{"caliper":["CALS"],"gammaRay":["GRR"],"other":["ASFL","CALS","CILD","DT","GRR","ILD","ILM","SFLU","SPR"],"sonic":["DT"],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -105.22843415794301,
      latitude: 43.11165733034373,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49009213050000_3064153_00136H1043740.LAS",
      name: "US49009213050000_3064153_00136H1043740.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49009213050000_3064153_00136H1043740.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRS","GRD"],"other":["ASFL","CALD","CILD","CORR","DT","GRD","GRS","PORD","RHOB","RILD","SPR"],"sonic":["DT"],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["RILD"]}
    },
    {
      longtitude: -105.72215284671088,
      latitude: 43.2308272594535,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49009211090000_3064153_00134H723390.LAS",
      name: "US49009211090000_3064153_00134H723390.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49009211090000_3064153_00134H723390.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["CALD","CILD","CORR","DPHI","GRD","RHOB","RILD","RILM","RLL8","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["RILD"]}
    },
    {
      longtitude: -106.62180029045305,
      latitude: 44.23266482063215,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US49019202750000_3064153_00146H860438.LAS",
      name: "US49019202750000_3064153_00146H860438.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US49019202750000_3064153_00146H860438.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRD"],"other":["ALL8","CALD","CILD","CORR","DPHI","GRD","ILD","RHOB","SPR"],"sonic":[],"neutron":[],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -119.130544,
      latitude: 35.234539,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US04029323490000_3064153_00005H1615773.LAS",
      name: "US04029323490000_3064153_00005H1615773.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US04029323490000_3064153_00005H1615773.LAS",
        demoRankMnemonics:{"caliper":["CALS"],"gammaRay":[],"other":["ASN","CALS","CILD","DT","ILD","SPR","SPS"],"sonic":["DT"],"neutron":[],"bulkDensity":[],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -119.13254127699766,
      latitude: 35.0091893236264,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US04029599250000_3064153_00007H1593185.LAS",
      name: "US04029599250000_3064153_00007H1593185.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US04029599250000_3064153_00007H1593185.LAS",
      demoRankMnemonics:{"caliper":[],"gammaRay":["GRR"],"other":["ASFL","CILD","GRR","RILD","SPR"],"sonic":[],"neutron":[],"bulkDensity":[],"deepResistivity":["RILD"]}
    },
    {
      longtitude: -119.58404,
      latitude: 35.353896,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US04030148810000_3064153_00013H732225.LAS",
      name: "US04030148810000_3064153_00013H732225.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US04030148810000_3064153_00013H732225.LAS",
        demoRankMnemonics:{"caliper":["CALR"],"gammaRay":["GRR"],"other":["AT20","AT60","AT90","CALR","DPHI","DRHO","GRR","NPOR"],"sonic":[],"neutron":["NPOR"],"bulkDensity":[],"deepResistivity":["AT90","AT60"]}
    },
    {
      longtitude: -118.869872,
      latitude: 35.225539,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US04029866330000_3064153_00011H1596056.LAS",
      name: "US04029866330000_3064153_00011H1596056.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US04029866330000_3064153_00011H1596056.LAS",
        demoRankMnemonics:{"caliper":["CALR"],"gammaRay":["GRR"],"other":["CALR","DRHO","GRR","IDPH","ILD","ILM","IMPH","NPHI_SS","RHOB","SFLU","SPR"],"sonic":[],"neutron":["NPHI_SS"],"bulkDensity":["RHOB"],"deepResistivity":["IDPH","ILD"]}
   },
    {
      longtitude: -119.16814249633322,
      latitude: 35.0032397527611,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US04029825130100_3064153_00009H4037436.LAS",
      name: "US04029825130100_3064153_00009H4037436.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US04029825130100_3064153_00009H4037436.LAS",
        demoRankMnemonics:{"caliper":["CALD","CALR"],"gammaRay":["GRR"],"other":["ASFL","CALD","CALR","CILD","DRHO","GRR","ILD","NPHI_SS","RHOB","SPR"],"sonic":[],"neutron":["NPHI_SS"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -119.93856641423415,
      latitude: 36.04182291488293,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US04031201270000_3064153_00017H1607308.LAS",
      name: "US04031201270000_3064153_00017H1607308.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US04031201270000_3064153_00017H1607308.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["GRR","GRD"],"other":["CALD","CORR","DPHI_SS","GRD","GRR","ILD","ILM","LL8","NPHI_SS","RHOB","SPR"],"sonic":[],"neutron":["NPHI_SS"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -119.71894079683835,
      latitude: 35.61638290413,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US04030200300000_3064153_00015H1039888.LAS",
      name: "US04030200300000_3064153_00015H1039888.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US04030200300000_3064153_00015H1039888.LAS",
        demoRankMnemonics:{"caliper":["HCAL"],"gammaRay":["GRR"],"other":["AT20","AT60","AT90","DPHZ","GRR","HCAL","HDRA","SPR","TNPH"],"sonic":[],"neutron":["TNPH"],"bulkDensity":[],"deepResistivity":["AT60","AT90"]}
    },
    {
      longtitude: -107.50310592522585,
      latitude: 36.9539951797833,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US30045294850000_3064153_00039H4814773.LAS",
      name: "US30045294850000_3064153_00039H4814773.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US30045294850000_3064153_00039H4814773.LAS",
        demoRankMnemonics:{"caliper":[],"gammaRay":["GRD"],"other":["GRD","NPHI_SS"],"sonic":[],"neutron":["NPHI_SS"],"bulkDensity":[],"deepResistivity":[]}
    },
    {
      longtitude: -107.5025054678728,
      latitude: 36.84608572872717,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US30039245490000_3064153_00037H853723.LAS",
      name: "US30039245490000_3064153_00037H853723.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US30039245490000_3064153_00037H853723.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["SGR"],"other":["CALD","DPHI","DRHO","ILD","ILM","NPHI","PEF","RHOB","SFLU","SGR","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -109.04866547163421,
      latitude: 37.202594728141015,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US43037307480000_3064153_00124H670258.LAS",
      name: "US43037307480000_3064153_00124H670258.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US43037307480000_3064153_00124H670258.LAS",
        demoRankMnemonics: {"caliper":["CALD"],"gammaRay":["GRR"],"other":["CALD","DPHI","DRHO","DT","GRR","ILD","ILM","NPHI","RHOB","SFLU","SPR"],"sonic":["DT"],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -107.56391030102755,
      latitude: 36.903035882747304,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US30045309600000_3064153_00041H895175.LAS",
      name: "US30045309600000_3064153_00041H895175.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US30045309600000_3064153_00041H895175.LAS",
        demoRankMnemonics:{"caliper":["CALR"],"gammaRay":["GRR"],"other":["CALR","DCOR","DFL","DPOR","GRR","HDRS","HMRS","NPOR","PE","RHOB","SPR"],"sonic":[],"neutron":["NPOR"],"bulkDensity":["RHOB"],"deepResistivity":["HDRS"]}
    },
    {
      longtitude: -107.54730719042912,
      latitude: 36.80457571900322,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US30039245180000_3064153_00035H853725.LAS",
      name: "US30039245180000_3064153_00035H853725.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US30039245180000_3064153_00035H853725.LAS",
        demoRankMnemonics:{"caliper":["CALD"],"gammaRay":["SGR"],"other":["CALD","DPHI","DRHO","ILD","ILM","NPHI","PEF","RHOB","SFLU","SGR","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    },
    {
      longtitude: -107.31511020024097,
      latitude: 36.4045558262875,
      inputPath:
        "prod-tgs-ai-arlas-input-data/demo/US30039237860000_3064153_00033H888803.LAS",
      name: "US30039237860000_3064153_00033H888803.LAS",
      outputPath:
        "prod-tgs-ai-arlas-output-data/demo/ARLAS_US30039237860000_3064153_00033H888803.LAS",
        demoRankMnemonics:{"caliper":["CALR"],"gammaRay":["GRR"],"other":["CALR","CILD","DPHI","DRHO","GRR","ILD","ILM","NPHI","RHOB","SFLU","SPR"],"sonic":[],"neutron":["NPHI"],"bulkDensity":["RHOB"],"deepResistivity":["ILD"]}
    }

      ],
  
};
