import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withAuth } from "@okta/okta-react";
//import logo from "../assets/images/TGS_Logo_White_Desktop.svg";
import ARLasLogo from "../assets/images/2024_TGS_logo_white.png";
import userIcon from "../assets/images/icons/userIcon.svg";
import useOutsideClick from "../components/useOutsideClick";
import { Modal } from "react-bootstrap";
import config from "../configs/appSettings";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
/* eslint-disable jsx-a11y/anchor-is-valid */

const AppHeader = props => {
  const logoutRef = useRef();
  const logoutIconRef = useRef();
  const [showjobHistory, setshowjobHistory] = useState(false);
  const [jobdata, setjobdata] = useState([]);

  const logoutHandle = async () => {
    try {
      await props.auth.logout("/");
    } catch (err) {
      props.history.push("/");
    }
  };
  useOutsideClick(logoutIconRef, () => {
    logoutRef.current.style.display = "none";
  });

  const logoutMenu = e => {
    if (logoutRef.current.style.display === "none") {
      logoutRef.current.style.display = "block";
    } else {
      logoutRef.current.style.display = "none";
    }
  };

  const userInfo = async () => {
    try {
      let userDetails = await props.auth.getUser();
      localStorage.setItem("userEmail", userDetails.email);
    } catch (err) {
      console.log(err);
    }
  };

  async function getAccessToken() {
    const accessToken = await props.auth.getAccessToken();
    return accessToken;
  }

  //Manage Job History Start
  const handleJobHistory = async () => {
    let jobuserDetails = localStorage.getItem("userEmail");
    setshowjobHistory(true);
    let jobinput = {};
    let url = `${config.endpoints.arlas}/ManageJobHistory`;

    jobinput = {
      end_point: "getJobHistory",
      user_email: jobuserDetails
    };
    console.log(jobinput);
    const accessToken = await getAccessToken();
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken
      }
    };
    const jobresult = axios.post(url, jobinput, headers);
    jobresult
      .then(
        res => {
          setjobdata(res.data);
        },
        error => {
          console.log(error.response.data.ErrorMessage);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
  //Manage Job History End

  useEffect(() => {
    userInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className="arlas-header-section">
      <div className="logo-section">
        <a href="" className="arlas-logo">
          <img src={ARLasLogo} alt="TGS logo" />
        </a>
        <span className="arlas-title">ARLAS On Demand</span>
        <div className="arlas-logout">
          {/* Job History Button */}
          {/* <div
            style={{
              position: "absolute",
              width: "145px",
              right: "100px",
              top: "8px"
            }}
          >
            <button
              id="jobhistory1"
              className="arlas-btn primary"
              data-toggle="modal"
              data-target="#jobhistorymodal"
              onClick={handleJobHistory}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
            >
              Job History
            </button>
          </div> */}
          {/* Job History Button End */}
          <div className="btn-group userIconGroup">
            <img
              className="userIcon"
              src={userIcon}
              alt="userIcon"
              onClick={logoutMenu}
              ref={logoutIconRef}
            />

            <div
              style={{ display: "none" }}
              className="dropdown-menu"
              ref={logoutRef}
            >
              <a className="dropdown-item">
                {localStorage.getItem("userEmail")}
              </a>
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                id="jobhistory1"
                style={{ cursor: "pointer" }}
                data-toggle="modal"
                data-target="#jobhistorymodal"
                onClick={handleJobHistory}
              >
                Job History
              </a>
              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={logoutHandle}
                id="logoutClickHandler"
              >
                Logout
              </a>
            </div>
          </div>
          {/* <a href="" className="tgs-logo">
            <img src={logo} alt="TGS logo" />
          </a> */}
        </div>
      </div>
      {/* Job History Modal Popup Start */}
      <Modal
        show={showjobHistory}
        onHide={() => setshowjobHistory(false)}
        centered
        dialogClassName="arlas-history-modal"
        data-keyboard="false"
        backdrop="static"
      >
        <Modal.Header className="jobheader">
          {/* <h4 className="modal-title">History</h4> */}
          <button
            type="button"
            className="jobhistoryclose-custom"
            onClick={() => setshowjobHistory(false)}
          >
            X
          </button>
        </Modal.Header>
        <Modal.Body
          className="jobmodal-body"
          style={{ overflowY: "auto", height: "420px", paddingTop: "20px" }}
        >
          <div style={{ padding: "10px", height: "350px", overflowY: "auto" }}>
            <table className="table table-bordered table-striped">
              <thead className="jobHistoryHeader ">
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>FileName</th>
                  <th style={{ whiteSpace: "nowrap" }}>Basin</th>
                  <th style={{ whiteSpace: "nowrap" }}>Date</th>
                  <th style={{ whiteSpace: "nowrap" }}>Download</th>
                </tr>
              </thead>
              <tbody className="jobHistoryContent">
                {jobdata.map((x, index) => (
                  <tr key={index}>
                    <td style={{ verticalAlign: "middle" }}>{x.file_name}</td>
                    <td style={{ verticalAlign: "middle" }}>{x.basin}</td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {/* {x.file_conversion_timestamp} */}
                      <Moment locale="en" format="YYYY-MM-DD HH:mm">
                        {x.file_conversion_timestamp + " UTC"}
                      </Moment>
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <a
                        style={{ position: "relative", top: "3px" }}
                        href={x.file_download_url}
                      >
                        <i className="ci ic-download"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      {/* Job History Modal Popup End */}
    </header>
  );
};

export default withRouter(withAuth(AppHeader));
