import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Security, SecureRoute, ImplicitCallback } from "@okta/okta-react";
import config from "./configs/appSettings";
import AccessDenied from "./pages/AccessDenied";
import CreateJob from "./pages/CreateJob/CreateJob";
import Profile from "./pages/Profile";

import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/scss/main.scss";
// import "./assets/style/App.scss";

const { issuer, clientId, scope } = config.oidc;
const callbackPath = "/implicit-callback";

class App extends React.Component {
  render() {
    return (
      // <div className="arlas-wrapper">
      <Router>
        <Security
          issuer={issuer}
          client_id={clientId}
          scope={scope}
          redirect_uri={window.location.origin + callbackPath}
          className="arlas-wrapper"
        >
          {/* <Route path="/" exact component={CreateJob} /> */}
          <SecureRoute path="/" exact component={CreateJob} />
          <Route path={callbackPath} component={ImplicitCallback} />
          <Route path="/access-denied" component={AccessDenied} />
          <SecureRoute path="/profile" component={Profile} />
        </Security>
      </Router>
      // </div>
    );
  }
}

export default App;
