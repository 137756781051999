import React from "react";
import { withAuth } from "@okta/okta-react";
import { Container, Row, Col, Table } from "react-bootstrap";
import Loading from "../components/Loading";

import { checkAuthentication } from "../components/helpers";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userinfo: null,
      ready: false,
      removeFromGroupInProgress: false
    };
    this.checkAuthentication = checkAuthentication.bind(this);
  }

  async componentDidMount() {
    await this.checkAuthentication();
    this.applyClaims();
  }

  async componentDidUpdate() {
    await this.checkAuthentication();
    this.applyClaims();
  }

  async applyClaims() {
    if (this.state.userinfo && !this.state.claims) {
      const claims = Object.entries(this.state.userinfo);
      this.setState({ claims, ready: true });
    }
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            {!this.state.ready && (
              <p>
                <Loading /> Fetching user profile...
              </p>
            )}
            {this.state.ready && (
              <div>
                <h1>My User Profile (ID Token Claims) </h1>
                <Table className="table">
                  <thead>
                    <tr>
                      <th>Claim</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.claims.map(claimEntry => {
                      const claimName = claimEntry[0];
                      const claimValue = claimEntry[1];
                      const claimId = `claim-${claimName}`;
                      return (
                        <tr key={claimName}>
                          <td>{claimName}</td>
                          <td id={claimId}>{claimValue}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withAuth(Profile);
