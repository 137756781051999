export default {
  basinOptions: [{ name: "Anadarko" }, { name: "Permian" }],
  basin: "Permian",
  lasFile: "",
  latitude: "",
  longitude: "",
  mnemonicOptions: {
    gammaRay: [],
    deepResistivity: [],
    neutron: [],
    bulkDensity: [],
    sonic: [],
    caliper: [],
    other: []
  }
};
