import React from "react";
import { Modal } from "react-bootstrap";

const AgreementModal = ({ show, agree, disAgree }) => {
  const file = "./LegalAgreement.pdf#view=FitH&toolbar=0";
    //"https://s3.amazonaws.com/TGSR360/TGS.AI/ARLAS_On_Demand_Legal.docx";
    //"https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  //const type = "docx";

  return (
    <Modal dialogClassName="modal-agreement" show={show}>
      <Modal.Header>
        <h4 className="modal-title">Legal Agreement to ARLAS On Demand</h4>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* <iframe
            className={type}
            width="100%"
            height="400"
            frameBorder="0"
            src={`https://docs.google.com/gview?url=${file}&embedded=true`}
          ></iframe> */}
          <object data={file} type="application/pdf" width="100%" height="400px">            
          </object>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ float: "right" }}>
          <button
            onClick={disAgree}
            className="arlas-btn primary"
            style={{ marginRight: "5px" }}
          >
            Cancel
          </button>

          <button onClick={agree} className="arlas-btn primary">
            I Agree
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AgreementModal;
