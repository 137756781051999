import React from "react";
import * as d3 from "d3";
import axios from "axios";

export class ARLasChart extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    if (this.props.arlasInformation && this.props.arlasInformation.length === 0)
      return;
    this.arlasCurveData = this.props.arlasInformation;
    let lasHeight = 500;
    let lasWidth = 120;
    let Arlastopdepth = this.props.topdepth;
    let Arlasbasedepth = this.props.basedepth;
    let ArminX = this.props.minX;
    let ArmaxX = this.props.maxX;
    let arP95data = [];
    let xScale = "";
    const arheaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.accesstoken
      }
    };

    /*P5 and P95 service*/
    var p5promise = new Promise((resolve, reject) => {
      if (this.props.curveName[0] === "gammaRay") {
        const gammaRayP95 = axios.post(
          this.props.purl,
          {
            ARLasFilePath: this.props.path,
            curveName: "GR_TGSAR_P5:GR_TGSAR_P95"
          },
          arheaders
        );
        gammaRayP95.then(res2 => {
          arP95data.push(Object.values(res2.data.ARLASCurveInformations));
          resolve();
        });
      } else if (this.props.curveName[0] === "deepResistivity") {
        const deepResistivityP95 = axios.post(
          this.props.purl,
          {
            ARLasFilePath: this.props.path,
            curveName: "RES_TGSAR_P5:RES_TGSAR_P95"
          },
          arheaders
        );
        deepResistivityP95.then(res2 => {
          arP95data.push(Object.values(res2.data.ARLASCurveInformations));
          resolve();
        });
      } else if (this.props.curveName[0] === "neutron") {
        const neutronP95 = axios.post(
          this.props.purl,
          {
            ARLasFilePath: this.props.path,
            curveName: "NEUT_TGSAR_P5:NEUT_TGSAR_P95"
          },
          arheaders
        );
        neutronP95.then(res2 => {
          arP95data.push(Object.values(res2.data.ARLASCurveInformations));
          resolve();
        });
      } else if (this.props.curveName[0] === "sonic") {
        const sonicP95 = axios.post(
          this.props.purl,
          {
            ARLasFilePath: this.props.path,
            curveName: "SON_TGSAR_P5:SON_TGSAR_P95"
          },
          arheaders
        );
        sonicP95.then(res2 => {
          arP95data.push(Object.values(res2.data.ARLASCurveInformations));
          resolve();
        });
      } else if (this.props.curveName[0] === "bulkDensity") {
        const bulkDensityP95 = axios.post(
          this.props.purl,
          {
            ARLasFilePath: this.props.path,
            curveName: "DEN_TGSAR_P5:DEN_TGSAR_P95"
          },
          arheaders
        );
        bulkDensityP95.then(res2 => {
          arP95data.push(Object.values(res2.data.ARLASCurveInformations));
          resolve();
        });
      } else if (this.props.curveName[0] === "caliper") {
        resolve();
      }
    });

    p5promise.then(
      () => {
        if (this.props.curveName[0] === "deepResistivity") {
          xScale = d3
            .scaleLog()
            .domain([ArminX[0], ArmaxX[0]])
            .nice()
            .range([0, lasWidth]); // Xaxis Scale
        } else {
          xScale = d3
            .scaleLinear()
            .domain([ArminX[0], ArmaxX[0]])
            .nice()
            .range([0, lasWidth]); // Xaxis Scale
        }

        const yScale = d3
          .scaleLinear()
          .domain([Arlasbasedepth[0], Arlastopdepth[0]])
          .range([lasHeight - 100, 0]); // Yaxis Scale
        let xAxis = "";
        if (this.props.curveName[0] === "deepResistivity") {
          xAxis = d3
            .axisTop(xScale)
            .tickSize(-lasHeight + 100)
            .ticks(4, ",.1s");
        } else {
          xAxis = d3
            .axisTop(xScale)
            .tickSize(-lasHeight + 100)
            .ticks(4); // Xaxis
        }
        const yAxis = d3
          .axisLeft(yScale)
          .tickSize(-lasWidth)
          .ticks(10); // Y axis
        const line = d3
          .line()
          .x(function(d) {
            return xScale(d[0]);
          })
          .y(function(d) {
            return yScale(d[1]);
          })
          .curve(d3.curveCardinal); // Cardinal graph generater

        let aryxissvg = "";
        let svg = "";
        let aryxissvgtext = "";
        if (this.props.curveName[0] === "gammaRay") {
          svg = d3
            .select(".arlas_gammaRay")
            .attr("class", "arlas_gammaRay active")
            .append("svg")
            .attr("height", lasHeight - 20)
            .attr("width", lasWidth + 50);
            this.props.arlasCurveRenderCount(this.props.curveName[0])
        } else if (this.props.curveName[0] === "deepResistivity") {
          svg = d3
            .select(".arlas_deepResistivity")
            .attr("class", "arlas_deepResistivity active")
            .append("svg")
            .attr("height", lasHeight - 20)
            .attr("width", lasWidth + 50);
            this.props.arlasCurveRenderCount(this.props.curveName[0])
        } else if (this.props.curveName[0] === "neutron") {
          svg = d3
            .select(".arlas_neutron")
            .attr("class", "arlas_neutron active")
            .append("svg")
            .attr("height", lasHeight - 20)
            .attr("width", lasWidth + 50);
            this.props.arlasCurveRenderCount(this.props.curveName[0])
        } else if (this.props.curveName[0] === "bulkDensity") {
          svg = d3
            .select(".arlas_bulkDensity")
            .attr("class", "arlas_bulkDensity active")
            .append("svg")
            .attr("height", lasHeight - 20)
            .attr("width", lasWidth + 50);
            this.props.arlasCurveRenderCount(this.props.curveName[0])
        } else if (this.props.curveName[0] === "sonic") {
          svg = d3
            .select(".arlas_sonic")
            .attr("class", "arlas_sonic active")
            .append("svg")
            .attr("height", lasHeight - 20)
            .attr("width", lasWidth + 50);
          //temporary fix for loader start
          //document.getElementById("arlasboxloader").style.display = "none";
          //temporary fix for loader End
          this.props.arlasCurveRenderCount(this.props.curveName[0])
        } else if (this.props.curveName[0] === "caliper") {
          svg = d3
            .select(".arlas_caliper")
            .attr("class", "arlas_caliper active")
            .append("svg")
            .attr("height", lasHeight - 20)
            .attr("width", lasWidth + 50);
            this.props.arlasCurveRenderCount(this.props.curveName[0])
        }

        svg
          .append("rect")
          .attr("height", lasHeight - 20)
          .attr("width", lasWidth + 50)
          .attr("class", "rectcontainer")
          .style("fill", "white");

        const chartGroup = svg
          .append("g")
          .attr("height", lasHeight - 20)
          .attr("width", lasWidth)
          .attr("transform", "translate(20,50)");

        const grpXAxis = chartGroup
          .append("g")
          .attr("class", "xaxis")
          .attr("transform", "translate(6,0)")
          .transition();
        grpXAxis.call(xAxis);

        d3.select(".aryxissvg svg").remove();
        d3.select(".aryxissvgtext svg").remove();
        aryxissvg = d3.select(".aryxissvg");

        aryxissvgtext = d3.select(".aryxissvgtext");

        aryxissvgtext
          .append("svg")
          .attr("height", lasHeight)
          .attr("width", 45)
          .append("text")
          .attr("x", "20")
          .attr("y", "50%")
          .attr("dominant-baseline", "middle")
          .attr("text-anchor", "middle")
          .attr("class", "curvedepthtitle")
          .text("(" + this.props.depthUnit + ")")
          .attr("fill", "#fff");

        const grpYAxis = aryxissvg
          .append("svg")
          .attr("height", lasHeight)
          .attr("width", 35)
          .append("g")
          .attr("class", "axisred yaxis")
          .attr("transform", "translate(33,50)")
          .transition();
        grpYAxis.call(yAxis);

        const grp1YAxis = chartGroup
          .append("g")
          .attr("class", "axisred yaxis")
          .attr("transform", "translate(6,0)")
          .transition();
        grp1YAxis.call(yAxis);
        //loop path based split

        if (this.props.curveName[0] !== "caliper") {
          let p95datalength = arP95data[0][0][1].length;
          for (let l = 0; l < p95datalength; l++) {
            chartGroup
              .append("path")
              .transition()
              .attr("transform", "translate(8,0)")
              .attr("d", line(arP95data[0][0][1][l]))
              .attr("class", "lasChart p95Chart")
              .style("display", "block")
              .attr("stroke-width", 1)
              .attr("stroke", "#00AAAB")
              .attr("fill", "none");
          }
          
        }
        let nonprdictLength = this.arlasCurveData[0][0][0].length;
        for (let i = 0; i < nonprdictLength; i++) {
          chartGroup
            .append("path")
            .transition()
            .attr("transform", "translate(8,0)")
            .attr("d", line(this.arlasCurveData[0][0][0][i]))
            .attr("class", "lasChart nonp95Chart")
            .style("display", "block")
            .attr("stroke-width", 1)
            .attr("stroke", "#003660")
            .attr("fill", "none");
        }

        //loop path based split
        let prdictLength = this.arlasCurveData[0][0][1].length;
        for (let k = 0; k < prdictLength; k++) {
          chartGroup
            .append("path")
            .transition()
            .attr("transform", "translate(8,0)")
            .attr("d", line(this.arlasCurveData[0][0][1][k]))
            .attr("class", "lasChart purpleChart")
            .style("display", "block")
            .attr("stroke-width", 1)
            .attr("stroke", "#AA0071")
            .attr("fill", "none");
        }

        svg
          .append("text")
          .attr("x", "50%")
          .attr("y", "20")
          .attr("dominant-baseline", "middle")
          .attr("text-anchor", "middle")
          .attr("class", "curvesubtitle")
          .text(this.props.curveId + "." + this.props.curveUnit)
          .attr("fill", "#003660");
      }

      /*P5 and P95 service end */
    );
  };

  render() {
    return "";
  }
}
