import React, { useState, useEffect } from "react";

const MnemonicList = ({ name, value, data, disabled, onChange }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const selectChangeHandler = e => {
    setSelectedValue(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue("");
    }
  }, [value, selectedValue]);

  return (
    <>
      <label
        className="label-styles"
        style={{ display: `${!!selectedValue ? "none" : "block"}` }}
      >
        {name === "caliper" ? "Select" : "Other"}
      </label>
      <select
        className="arlas-select"
        name={name}
        value={selectedValue}
        disabled={disabled}
        onChange={selectChangeHandler}
      >
        <option disabled></option>
        {data.map(item => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </>
  );
};

export default MnemonicList;
