import React from "react";
import * as d3 from "d3";

export class LasChart extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.lasCurveData = Object.values(this.props.lasInformation);
    this.lasCurveID = Object.values(this.lasCurveData[0].LASCurveInformations);
    let lasHeight = 500;
    let lasWidth = 120;
    let lastopdepth = this.lasCurveData[0].TopDepth;
    let lasbasedepth = this.lasCurveData[0].EndDepth;
    let xScale = "";
    if (this.props.curveName[0] === "deepResistivity") {
      xScale = d3
        .scaleLog()
        .domain([
          this.lasCurveData[0].MinPointForX,
          this.lasCurveData[0].MaxPointForX
        ])
        .nice()
        .range([0, lasWidth]); // Xaxis Scale
    } else {
      xScale = d3
        .scaleLinear()
        .domain([
          this.lasCurveData[0].MinPointForX,
          this.lasCurveData[0].MaxPointForX
        ])
        .nice()
        .range([0, lasWidth]); // Xaxis Scale
    }
    const yScale = d3
      .scaleLinear()
      .domain([lasbasedepth, lastopdepth])
      .range([lasHeight - 100, 0]); // Yaxis Scale
    let xAxis = "";
    if (this.props.curveName[0] === "deepResistivity") {
      xAxis = d3
        .axisTop(xScale)
        .tickSize(-lasHeight + 100)
        .ticks(4, ",.1s");
    } else {
      xAxis = d3
        .axisTop(xScale)
        .tickSize(-lasHeight + 100)
        .ticks(4); // Xaxis
    }
    const yAxis = d3
      .axisLeft(yScale)
      .tickSize(-lasWidth)
      .ticks(10); // Y axis
    const line = d3
      .line()
      .x(function(d) {
        return xScale(d[0]);
      })
      .y(function(d) {
        return yScale(d[1]);
      })
      .curve(d3.curveCardinalOpen); // Cardinal graph generater

    let svg = "";
    let yxissvg = "";
    let yxissvgtext = "";

    if (this.props.curveName[0] === "gammaRay") {
      svg = d3
        .select(".las_gammaRay")
        .attr("class", "las_gammaRay active")
        .append("svg")
        .attr("height", lasHeight)
        .attr("width", lasWidth + 50);
    } else if (this.props.curveName[0] === "deepResistivity") {
      svg = d3
        .select(".las_deepResistivity")
        .attr("class", "las_deepResistivity active")
        .append("svg")
        .attr("height", lasHeight)
        .attr("width", lasWidth + 50);
    } else if (this.props.curveName[0] === "neutron") {
      svg = d3
        .select(".las_neutron")
        .attr("class", "las_neutron active")
        .append("svg")
        .attr("height", lasHeight)
        .attr("width", lasWidth + 50);
    } else if (this.props.curveName[0] === "bulkDensity") {
      svg = d3
        .select(".las_bulkDensity")
        .attr("class", "las_bulkDensity active")
        .append("svg")
        .attr("height", lasHeight)
        .attr("width", lasWidth + 50);
    } else if (this.props.curveName[0] === "sonic") {
      svg = d3
        .select(".las_sonic")
        .attr("class", "las_sonic active")
        .append("svg")
        .attr("height", lasHeight)
        .attr("width", lasWidth + 50);
    } else if (this.props.curveName[0] === "caliper") {
      svg = d3
        .select(".las_caliper")
        .attr("class", "las_caliper active")
        .append("svg")
        .attr("height", lasHeight)
        .attr("width", lasWidth + 50);
    }
    svg
      .append("rect")
      .attr("height", lasHeight)
      .attr("width", lasWidth + 50)
      .attr("class", "rectcontainer")
      .style("fill", "white");

    const chartGroup = svg
      .append("g")
      .attr("height", lasHeight)
      .attr("width", lasWidth)
      .attr("transform", "translate(20,50)");

    const grpXAxis = chartGroup
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", "translate(6,0)")
      .transition();
    grpXAxis.call(xAxis);

    d3.select(".yxissvg svg").remove();
    d3.select(".yxissvgtext svg").remove();
    yxissvg = d3.select(".yxissvg");

    yxissvgtext = d3.select(".yxissvgtext");

    yxissvgtext
      .append("svg")
      .attr("height", lasHeight)
      .attr("width", 45)
      .append("text")
      .attr("x", "20")
      .attr("y", "50%")
      .attr("dominant-baseline", "middle")
      .attr("text-anchor", "middle")
      .attr("class", "curvedepthtitle")
      .text("(" + this.lasCurveData[0].DepthUnit + ")")
      .attr("fill", "#fff");

    const grpYAxis = yxissvg
      .append("svg")
      .attr("height", lasHeight)
      .attr("width", 35)
      .append("g")
      .attr("class", "axisred yaxis")
      .attr("transform", "translate(33,50)")
      .transition();
    grpYAxis.call(yAxis);

    const grp1XAxis = chartGroup
      .append("g")
      .attr("class", "axisred yaxis")
      .attr("transform", "translate(6,0)")
      .transition();
    grp1XAxis.call(yAxis);

    ///loop path based splid
    let nonpredict = this.lasCurveID[0][0].length;
    for (let i = 0; i < nonpredict; i++) {
      chartGroup
        .append("path")
        .transition()
        .attr("transform", "translate(8,0)")
        .attr("d", line(this.lasCurveID[0][0][i]))
        .attr("class", "lasChart")
        .attr("stroke-width", 1)
        .attr("stroke", this.props.color)
        .attr("fill", "none");
    }

    svg
      .append("text")
      .attr("x", "50%")
      .attr("y", "20")
      .attr("dominant-baseline", "middle")
      .attr("text-anchor", "middle")
      .attr("class", "curvesubtitle")
      .text(this.props.curveId + "." + this.lasCurveData[0].CurveUnit)
      .attr("fill", this.props.color);

    d3.select(".las_sonic").attr("class", "las_sonic active");
    d3.select(".las_caliper").attr("class", "las_caliper active");
    d3.select(".las_bulkDensity").attr("class", "las_bulkDensity active");
    d3.select(".las_deepResistivity").attr(
      "class",
      "las_deepResistivity active"
    );
    d3.select(".las_neutron").attr("class", "las_neutron active");
    d3.select(".las_gammaRay").attr("class", "las_gammaRay active");
  };

  render() {
    return "";
  }
}
