import config from "../../configs/appSettings";

const FILE_PROCESSING_STATES = {
  NONE: "",
  LOADING: "loading",
  COMPLETED: "completed",
  FAILED: "failed",
  INVALIDLAS: false
};

const getFileExtension = filename =>
  filename
    .split(".")
    .slice(-1)
    .pop()
    .toLowerCase();

const mapCoordinates = data => ({
  latitude: data.SurfaceLatitudeWGS84,
  longitude: data.SurfaceLongitudeWGS84
});

const processFile = async file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = async () => {
      const [type, rawData] = reader.result.split(",");
      const [, metaData] = type.split(":");
      const [contentType] = metaData.split(";");

      let binary = atob(rawData);
      let array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let blobData = new Blob([new Uint8Array(array)], {
        type: contentType
      });

      resolve({ filename: file.name, blobData, contentType });
    };

    reader.readAsDataURL(file);
  });
};

async function httpCall(
  url,
  payload,
  getAccessToken,
  method = "POST",
  callBack
) {
  const accessToken = await getAccessToken();
  let response = null;
  const fetchOptions = {
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  //Logout session Timeout centralized Code Start
  if (payload) fetchOptions.body = JSON.stringify(payload);
  await fetch(url, fetchOptions)
    .then(res => {
      if (res.status === 401 || res.status === 403) {
        alert("Session Expired. Please Re-Login");
        document.querySelector("#logoutClickHandler").click();
      } else {
        response = res;
      }
      if (res.status === 422) {
        alert("Invalid LAS file. Please upload new one");
        FILE_PROCESSING_STATES.INVALIDLAS = true;
        //        document.querySelector(".toggle-head-first").click();
      }
    })
    .catch(error => {
      alert(error);
    });

  const data = await response.json();
  return [response, data];

  //Logout session Timeout centralized Code End
  // if (payload) fetchOptions.body = JSON.stringify(payload);

  // const response = await fetch(url, fetchOptions);

  // const data = await response.json();
  // return [response, data];
}

async function getUploadURL(filename, contentType, getAccessToken) {
  const url = `${
    config.endpoints.arlasCommon
  }/uploadFilePresigned?filename=${encodeURIComponent(
    filename
  )}&contentType=${encodeURIComponent(contentType)}`;
  const [, data] = await httpCall(url, null, getAccessToken, "GET");
  return data;
}

function getMnemonic(mnemonic, formData) {
  const include = `${mnemonic}Include`;
  if (formData[include]) return formData[mnemonic];
  return "NaN";
}

function getPayload(formData, filePaths) {
  let bucketName = config.outputBucketName; // "dev-tgs-ai-arlas-output-data";
  let file = filePaths.las;
  let inputFile = file.replace("s3://", "");
  // eslint-disable-next-line
  let outputFile = file.replace(/^.*[\\\/]/, "");
  outputFile = outputFile.split(".")[0];
  let res = {
    inputBucket: inputFile,
    LAT: formData.latitude, //37.06236785393268,
    LON: formData.longitude, //-100.15761823576375,
    CALI: getMnemonic("caliper", formData),
    DEN: getMnemonic("bulkDensity", formData),
    GR: getMnemonic("gammaRay", formData),
    NEUT: getMnemonic("neutron", formData),
    RES: getMnemonic("deepResistivity", formData),
    SON: getMnemonic("sonic", formData),
    basin: formData.basin,
    outputBucket: `${bucketName}/ARLAS_UI/ARLAS_${outputFile}_TGS_ARLAS.las`
    //    outputBucket: `${bucketName}/demo/ARLAS_${outputFile}_TGS_ARLAS.las`
  };

  return res;

  /*return {
    LasFilePath: filePaths.las,
    ARLASFilePath: filePaths.arlas,
    Basin: formData.basin,
    Latitude: formData.latitude.toString(),
    Longitude: formData.longitude.toString(),
    Mnemonics: {
      Resistivity: getMnemonic("deepResistivity", formData),
      Sonic: getMnemonic("sonic", formData),
      Porosity: getMnemonic("neutron", formData),
      Cali: getMnemonic("caliper", formData),
      Gamma: getMnemonic("gammaRay", formData),
      Density: getMnemonic("bulkDensity", formData)
    }
  };*/
}

function validate(formData, filePaths) {
  let errors = {};

  if (formData.lasFile === "") {
    errors.lasFile = "LAS file is required";
  } else {
    if (getFileExtension(formData.lasFile) !== "las") {
      errors.lasFile = "File must be an las file type";
    }
  }

  if (formData.latitude === "") {
    errors.latitude = "Latitude is required";
  } else if (!(+formData.latitude >= -90 && +formData.latitude <= 90)) {
    errors.latitude = "Latitude must be between -90 and 90";
  }

  if (formData.longitude === "") {
    errors.longitude = "Longitude is required";
  } else if (!(+formData.longitude >= -180 && +formData.longitude <= 180)) {
    errors.longitude = "Longitude must be between -180 and 180";
  }

  if (formData.basin === "") {
    errors.basin = "Basin is required";
  }

  if (!formData.isBasinSupported) {
    errors.basin = "Basin, which is not yet supported";
  }

  // At Least one Mnemonic is required
  if (
    getMnemonic("deepResistivity", formData) === "" &&
    getMnemonic("sonic", formData) === "" &&
    getMnemonic("neutron", formData) === "" &&
    getMnemonic("gammaRay", formData) === "" &&
    getMnemonic("bulkDensity", formData) === ""
  ) {
    errors.mnemonic = config.mnemonicNonSelectionMessage;
  }

  let payload = {};
  if (Object.keys(errors).length === 0) {
    payload = getPayload(formData, filePaths);
  }

  return [errors, payload];
}

export {
  FILE_PROCESSING_STATES,
  getFileExtension,
  mapCoordinates,
  processFile,
  httpCall,
  getUploadURL,
  validate
};
