import React from "react";
import { Modal } from "react-bootstrap";

const ErrorModal = ({ show, onHide, message, title }) => {
  let _title = !!title ? title : "Error";
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <h4 className="modal-title">{_title}</h4>
        <button
          type="button"
          className="close-custom"
          onClick={() => onHide(false)}
        >
          X
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="px-5">{message}</div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
