import React, { useState, useEffect } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import Status from "./Status";
import { FILE_PROCESSING_STATES } from "./createJobHelper";

const UploadModal = ({ show, uploading, coordinates, choosingCurves }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    let progressBar = 0;
    progressBar += uploading === "completed" ? 33 : 10;
    progressBar += coordinates === "completed" ? 33 : 5;
    progressBar += choosingCurves === "completed" ? 33 : 5;
    setProgress(p => (p = progressBar));
  }, [uploading, coordinates, choosingCurves]);

  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      className="arlas-upload-section"
      dialogClassName="arlas-upload-modal"
    >
      <Modal.Body>
        <div id="arlasloader-upload" className="loader-section">
          <div className="arlas-loader"></div>
        </div>
        <div className="clearfix"></div>
        <div className="process-text">Processing your LAS file…</div>
        <div className="progress-bar-text-area">
          <div
            className={`progress-bar-text ${uploading ===
              FILE_PROCESSING_STATES.LOADING && "active"}`}
          >
            Uploading File  <Status status={uploading} />
          </div>
          <div
            className={`progress-bar-text ${coordinates ===
              FILE_PROCESSING_STATES.LOADING && "active"}`}
          >
            Getting Coordinates  <Status status={coordinates} />
          </div>
          <div
            className={`progress-bar-text ${choosingCurves ===
              FILE_PROCESSING_STATES.LOADING && "active"}`}
          >
            Choosing Curves  <Status status={choosingCurves} />
          </div>
        </div>
        <ProgressBar
          variant="success"
          now={progress}
          bsPrefix="progress-bar-path"
        />
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
