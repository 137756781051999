import React from "react";

const Mnemonic = ({ name, value, data, disabled, onChange }) => {
  return (
    <ul className="mnemonics">
      {data.map(item => (
        <li key={item}>
          <label className="arlas-radio">
            {item}
            <input
              type="radio"
              name={name}
              value={item}
              checked={value === item}
              disabled={disabled}
              onChange={onChange}
            />
            <span className="radiomark"></span>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default Mnemonic;
