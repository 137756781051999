import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useCreateJobHook } from "../CreateJob/useCreateJobHook";
import { withAuth } from "@okta/okta-react";
import AppLayout from "../../components/AppLayout";
import Loading from "../../components/Loading";
import FileUpload from "./FileUpload";
import Basin from "./Basin";
//import MnemonicList from "./MnemonicList";
import Mnemonic from "./Mnemonic";
import FormValidationModal from "./FormValidationModal";
import ErrorModal from "./ErrorModal";
import ConvertModal from "./ConvertModal";
import HistoryModal from "./HistoryModal";
import "./Map.css";
import MapArea from "../../components/Map";
import config from "../../configs/appSettings";
import AgreementModal from "../Agreement";
import LocalStore from "../../components/LocalStore";
import jwt_decode from "jwt-decode";
import axios from "axios";

const BasinMessage = ({ message }) => {
  let style = {
    backgroundColor: "#fc0000",
    color: "FFFFFF",
    padding: "5px 10px"
  };
  if (message === "Could not identify Basin") {
    style.backgroundColor = "#ffc107";
    style.color = "#000000";
  }

  return message === "" ? (
    ""
  ) : (
    <div style={style}>
      <span>{message}</span>
    </div>
  );
};

const CreateJob = props => {
  const [activePanel, setActivePanel] = useState("0");
  const [showAgreement, setShowAgreement] = useState(false);
  //const [viewArlas, setviewArlas] = useState(false);
  //const [userEmail, setuserEmail] = useState(null);

  const {
    formData,
    formErrors,
    mnemonicOptions,
    mnemonicNotIncluded,
    sinlgemnemonic,
    mnemonicGR,
    mnemonicDPR,
    mnemonicNEU,
    mnemonicBDEN,
    mnemonicCALI,
    setsingleMnemonic,
    setmnemonicGR,
    setmnemonicDPR,
    setmnemonicNEU,
    setmnemonicBDEN,
    setmnemonicCALI,
    filePaths,
    basinOptions,
    basinMessage,
    errorModal,
    historyModal,
    convertModal,
    converting,
    viewArlas,
    setviewArlas,
    enableDownloadButton,
    setEnableDownloadButton,
    enableSubmitButton,
    resetState,
    handleBasinChange,
    handleIncludeCheckbox,
    //convertionCount,
    // setconvertionCount,
    showErrorModal,
    closeErrorModal,
    closeValidationErrorModal,
    handleMnemonicChange,
    updateFilePath,
    updateCoordinates,
    updateRankMnemonics,
    updateBasin,
    handleHistoryClick,
    closeConvertModal,
    handleConvert,
    handleArlasConvert,
    handleDownloadFile,
    updateConverting,
    updateCount,
    invalidFileStatus,
    requestId,
    demoFlow,
    setdemoFlow,
    demoCurve,
    setdemoCurve,
    filterCurve,
    setfilterCurve,
    mapLatLong,
    setmapLatLong,
    LasElementShow,
    setLasElementShow
  } = useCreateJobHook(props);  
  //free tierconversion
  const [countObj, setcountObj] = useState(null);
  const [maxcountObj, setmaxcountObj] = useState(0);
  const [maxCountReach, setmaxCountReach] = useState(null);
  const [decodeState, setdecodeState] = useState(true);
  const [countObjColor, setcountObjColor] = useState(null);
  const [internalUser, setinternalUser] = useState(null);
  const [demoLoading, setdemoLoading] = useState(false);

  //free tierconversion

  const handleOnSelectPanel = e => {
    if (invalidFileStatus === false) {
      if (e === "1" && filePaths.las === "") return;
      setActivePanel(e);
      if (e === "0") {
        if (demoFlow === true) {
          setdemoFlow(false);
          updateFilePath("uploadedFile", "");
          updateFilePath("las", "");
          updateBasin("");
          setfilterCurve(true);
          setdemoCurve(true);
        }
      }
    }
  };

  const logoutHandle = async () => {
    try {
      await props.auth.logout("/");
    } catch (err) {
      props.history.push("/");
    }
  };

  useEffect(() => {
    if (filePaths.las !== "" && invalidFileStatus === false) {
      setActivePanel(p => (p = "1"));
    } else {
      setActivePanel(p => (p = "0"));
      //setdemoFlow(false);
    }
  }, [filePaths.las, invalidFileStatus]);

  useEffect(() => {
    if (demoFlow === true) {
      setActivePanel(p => (p = "1"));
    } else {
      setActivePanel(p => (p = "0"));
    }
    // eslint-disable-next-line
  }, [demoFlow]);

  // transparent
  //
  const mnemonicsBorderStyle = {
    border: `1px solid ${mnemonicNotIncluded ? "#F9A01F" : "transparent"}`
  };

  const agree = () => {
    LocalStore.setItem(
      `${localStorage.getItem("userEmail")}-arlas-agreementv1`,
      true
    );
    setShowAgreement(false);
  };
  const changebtnTextHandler = () => {
    setviewArlas(true);
  };

  async function getAccessToken() {
    const accessToken = await props.auth.getAccessToken();
    return accessToken;
  }

  const manageJobCount = async () => {
    const historyToken = await getAccessToken();
    let decodeToken = jwt_decode(historyToken);
    let userInternal = decodeToken["User-IsInternalUser"]; // "plainKey"
    //let userInternal = true; // "plainKey"
    //console.log(userInternal);
    setinternalUser(userInternal);
    //setinternalUser(false);
    let userDetails = localStorage.getItem("userEmail");

    const historyUrl = `${config.endpoints.arlas}/ManageJobHistory`;
    const historyInputdata = {
      end_point: "getAccessCount",
      //user_email: userDetails ? userDetails : ""
      user_email: userDetails
    };

    const historyHeaders = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + historyToken
      }
    };
    if (userInternal === false) {
      axios
        .post(historyUrl, historyInputdata, historyHeaders)
        .then(
          res => {
            setcountObj(res.data.access_count);
            //setcountObj(10);
            setmaxcountObj(res.data.max_access_count);
            if (countObj >= res.data.max_access_count) {
              if (res.data.max_access_count !== 0) {
                setcountObjColor("red");
                setmaxCountReach(true);
              }
            } else if (countObj >= res.data.max_access_count / 2) {
              setcountObjColor("yellow");
              setmaxCountReach(false);
            } else {
              if (countObj !== null) {
                setcountObjColor("#00aaab");
                setmaxCountReach(false);
              }
            }
          },
          error => {
            console.log("error");
          }
        )
        .catch(err => {});
    } else {
      setdecodeState(false);
      setmaxCountReach(true);
    }
  };

  const userInfo = async () => {
    let userDetails = localStorage.getItem("userEmail");
    let agreed = LocalStore.getJSON(`${userDetails}-arlas-agreementv1`);
    if (!agreed) {
      setShowAgreement(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      userInfo();
    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      manageJobCount();
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [converting, countObj]);

  return (
    <AppLayout>
      <AgreementModal
        show={showAgreement}
        agree={agree}
        disAgree={logoutHandle}
      />
      <ErrorModal
        show={errorModal.show}
        onHide={closeErrorModal}
        message={errorModal.error}
      />
      <FormValidationModal
        show={formErrors.show}
        onHide={closeValidationErrorModal}
        errors={formErrors.errors}
      />
      <HistoryModal show={historyModal} onHide={handleHistoryClick} />
      <ConvertModal
        show={convertModal}
        // show={false}
        onHide={closeConvertModal}
        converting={converting}
        enableDownloadButton={enableDownloadButton}
        handleDownloadFile={handleDownloadFile}
        lascurvepath={filePaths.las}
        mnemonics={formData}
        arlascurvepath={filePaths.arlas}
        changebtnText={changebtnTextHandler}
        basinname={formData.basin}
        arlasReqID={requestId}
        tgsSample={demoFlow}
        viewArlas={viewArlas}
        setviewArlas={setviewArlas}
        setEnableDownloadButton={setEnableDownloadButton}
        setLasElementShow={setLasElementShow}
        LasElementShow={LasElementShow}
      />

      <section className="arlas-body-section">
        <Accordion activeKey={activePanel} onSelect={handleOnSelectPanel}>
          <Card id="step1" className="arlas-toggle-section">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="toggle-head toggle-head-first"
            >
              <div className="toggle-arrow" aria-expanded={activePanel === "0"}>
                <h4>Step 1. Upload LAS</h4>
                <i className="ci ic-upndown"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="intro-text">
                  <span className="firsttext">ARLAS (Analytics Ready LAS)</span>{" "}
                  is a TGS proprietary series of algorithms that utilize machine
                  learning and the TGS well log database to calculate missing
                  well log information from wells. Gamma Ray, Resistivity,
                  Neutron, Density, and Sonic can all be calculated using this
                  groundbreaking technology. The ARLAS process creates complete
                  well log data sets that are ideal for further analysis using
                  machine learning techniques.
                </div>
                <div
                  className="intro-text"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <span className="firsttext">ARLAS on Demand</span> is a free
                  TGS proprietary service that allows you to convert your
                  standard LAS files into ARLAS data sets by following the
                  simple steps below. We hope you enjoy this free tool and share
                  with us your experience. To provide feedback and voice your
                  ideas, please contact us at{" "}
                  <a href="mailto:analytics@tgs.com" className="emailText">
                    analytics@tgs.com
                  </a>
                </div>
                <FileUpload
                  data={{ filePaths }}
                  decodeConversion={internalUser}
                  conversionObjColor={countObjColor}
                  conversioncountObj={countObj}
                  conversionmaxcountObj={maxcountObj}
                  conversionreach={maxCountReach}
                  tgsSample={demoFlow}
                  settgsSample={setdemoFlow}
                  demoCurve={demoCurve}
                  setdemoCurve={setdemoCurve}
                  filterCurve={filterCurve}
                  setfilterCurve={setfilterCurve}
                  mapLatLong={mapLatLong}
                  setmapLatLong={setmapLatLong}
                  setviewArlas={setviewArlas}
                  demoLoading={demoLoading}
                  setdemoLoading={setdemoLoading}
                  setLasElementShow={setLasElementShow}
                  LasElementShow={LasElementShow}
                  setsingleMnemonic={setsingleMnemonic}
                  setmnemonicGR={setmnemonicGR}
                  setmnemonicDPR={setmnemonicDPR}
                  setmnemonicNEU={setmnemonicNEU}
                  setmnemonicBDEN={setmnemonicBDEN}
                  setmnemonicCALI={setmnemonicCALI}
                  actions={{
                    showErrorModal,
                    closeErrorModal,
                    updateFilePath,
                    updateCoordinates,
                    updateRankMnemonics,
                    updateBasin,
                    resetState,
                    updateConverting,
                    updateCount,
                    handleBasinChange
                  }}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="step2" className="arlas-toggle-section">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              className="toggle-head"
            >
              <div className="toggle-arrow" aria-expanded={activePanel === "1"}>
                <h4>
                  Step 2. Select the desired basin algorithm and mnemonics (one
                  or more required). Optionally choose Caliper and Convert to
                  ARLAS.
                </h4>
                <i className="ci ic-upndown"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <BasinMessage message={basinMessage} />
                <div
                  id="mnemonics"
                  className="arlas-mnemonics collapse show mt-20 mb-10"
                  data-parent="#accordion"
                >
                  <div className="mnemonics-left">
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-6 basin-section">
                        <div className="basin-text mr-15">
                          Basin Algorithm :
                        </div>
                        <Basin
                          basinSelected={formData.basin}
                          data={basinOptions}
                          handleBasinChange={handleBasinChange}
                        />
                      </div>
                    </div>
                    {/* Map Area */}
                    <div className="arlas-map-area mt-10 esriMapContainer">
                      {demoFlow === false ? (
                        <MapArea
                          latitude={formData.latitude}
                          longitude={formData.longitude}
                          basinname={formData.basin}
                          tgsSample={demoFlow}
                          mapLatLong={mapLatLong}
                          setmapLatLong={setmapLatLong}
                          filterCurve={filterCurve}
                        />
                      ) : (
                        <MapArea
                          latitude={36.52333474301903}
                          longitude={-100.01850736009172}
                          basinname={formData.basin}
                          tgsSample={demoFlow}
                          mapLatLong={mapLatLong}
                          setmapLatLong={setmapLatLong}
                          filterCurve={filterCurve}
                        />
                      )}
                    </div>
                    {/* / Map Area */}
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="coordinates"
                          style={{
                            display: formData.latitude !== "" ? "block" : "none"
                          }}
                        >
                          <label>Latitude: </label>
                          <span>{formData.latitude}</span>

                          <label>Longitude: </label>
                          <span>{formData.longitude}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mnemonics-right">
                    <div
                      className="mnemonics-mandatory"
                      style={mnemonicsBorderStyle}
                    >
                      {/* add this class for validation fail "mandatory-field" */}
                      {demoCurve === false ? (
                        <div
                          className="mnemonics-curve"
                          style={{ height: "400px" }}
                        >
                          <div className="row">
                            <div
                              className="col-12 curve-title text-center"
                              style={{ marginTop: "170px" }}
                            >
                              Please Select well from map <br />
                              <Loading loading={demoLoading} />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {demoCurve === true ? (
                        <Mnemonic
                          mnemonics={mnemonicOptions.gammaRay}
                          allMnemonics={mnemonicOptions.other}
                          name="gammaRay"
                          displayName="Gamma Ray"
                          value={formData.gammaRay}
                          include={formData.gammaRayInclude}
                          onChange={handleMnemonicChange}
                          onChangeCheckbox={handleIncludeCheckbox}
                        />
                      ) : null}
                      {demoCurve === true ? (
                        <Mnemonic
                          mnemonics={mnemonicOptions.deepResistivity}
                          allMnemonics={mnemonicOptions.other}
                          name="deepResistivity"
                          displayName="Deep Resistivity"
                          value={formData.deepResistivity}
                          include={formData.deepResistivityInclude}
                          onChange={handleMnemonicChange}
                          onChangeCheckbox={handleIncludeCheckbox}
                        />
                      ) : null}
                      {demoCurve === true ? (
                        <Mnemonic
                          mnemonics={mnemonicOptions.neutron}
                          allMnemonics={mnemonicOptions.other}
                          name="neutron"
                          displayName="Neutron Porosity"
                          value={formData.neutron}
                          include={formData.neutronInclude}
                          onChange={handleMnemonicChange}
                          onChangeCheckbox={handleIncludeCheckbox}
                        />
                      ) : null}
                      {demoCurve === true ? (
                        <Mnemonic
                          mnemonics={mnemonicOptions.bulkDensity}
                          allMnemonics={mnemonicOptions.other}
                          name="bulkDensity"
                          displayName="Bulk Density"
                          value={formData.bulkDensity}
                          include={formData.bulkDensityInclude}
                          onChange={handleMnemonicChange}
                          onChangeCheckbox={handleIncludeCheckbox}
                        />
                      ) : null}
                      {demoCurve === true ? (
                        <Mnemonic
                          mnemonics={mnemonicOptions.sonic}
                          allMnemonics={mnemonicOptions.other}
                          name="sonic"
                          displayName="P-Wave Sonic"
                          value={formData.sonic}
                          include={formData.sonicInclude}
                          onChange={handleMnemonicChange}
                          onChangeCheckbox={handleIncludeCheckbox}
                        />
                      ) : null}
                    </div>
                    <p className="mandatory-text ml-5px mb-10 mt-5px">
                      {mnemonicNotIncluded &&
                        config.mnemonicNonSelectionMessage}
                      &nbsp;
                    </p>
                    {sinlgemnemonic ? (
                      <p
                        id="singlemessage"
                        className="mandatory-text mnemonic-error ml-5px mb-10 mt-5px"
                      >
                        Please select a curve for P-Wave Sonic
                      </p>
                    ) : null}
                    {mnemonicGR ? (
                      <p
                        id="messageGR"
                        className="mandatory-text mnemonic-error ml-5px mb-10 mt-5px"
                      >
                        Please select a curve for Gamma Ray
                      </p>
                    ) : null}
                    {mnemonicDPR ? (
                      <p
                        id="messageDPR"
                        className="mandatory-text mnemonic-error ml-5px mb-10 mt-5px"
                      >
                        Please select a curve for Deep Resistivity
                      </p>
                    ) : null}
                    {mnemonicNEU ? (
                      <p
                        id="messageNEU"
                        className="mandatory-text mnemonic-error ml-5px mb-10 mt-5px"
                      >
                        Please select a curve for Neutron Porosity
                      </p>
                    ) : null}
                    {mnemonicBDEN ? (
                      <p
                        id="messageBDEN"
                        className="mandatory-text mnemonic-error ml-5px mb-10 mt-5px"
                      >
                        Please select a curve for Bulk Density
                      </p>
                    ) : null}
                    {mnemonicCALI ? (
                      <p
                        id="messageCALI"
                        className="mandatory-text mnemonic-error ml-5px mb-10 mt-5px"
                      >
                        Please select a curve for Caliper
                      </p>
                    ) : null}

                    <div className="mnemonics-optional">
                      {/* <div
                        className={`mnemonics-curve ${!formData.caliperInclude &&
                          "deselect"}`}
                      > */}
                      {/* Included for Caliper Start*/}
                      {demoCurve === true ? (
                        <Mnemonic
                          mnemonics={mnemonicOptions.caliper}
                          allMnemonics={mnemonicOptions.other}
                          name="caliper"
                          displayName="Caliper"
                          value={formData.caliper}
                          include={formData.caliperInclude}
                          onChange={handleMnemonicChange}
                          onChangeCheckbox={handleIncludeCheckbox}
                        />
                      ) : null}
                      {/* Included for Caliper End */}

                      {/* <div className="row">
                          <div className="col-7 curve-title">Caliper</div>
                          <div className="col-5 curve-select text-right">
                            <label className="arlas-checkbox">
                              Use this curve
                              <input
                                type="checkbox"
                                name="caliperInclude"
                                checked={formData.caliperInclude}
                                onChange={handleIncludeCheckbox}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div> */}
                      {/* <div className="row mt-15">
                          <div className="col-7"></div>
                          <div className="col-5 text-right mt-5px">
                            <MnemonicList
                              data={mnemonicOptions.other}
                              name="caliper"
                              value={formData.caliper}
                              onChange={handleMnemonicChange}
                              disabled={!formData.caliperInclude}
                            />
                          </div>
                        </div> */}
                      {/* </div> */}
                    </div>
                    <div className="row">
                      <div className="col-12 text-right">
                        {decodeState && !internalUser && demoFlow === false ? (
                          <span
                            id="conversionCount"
                            style={{
                              color: countObjColor,
                              fontSize: "18px",
                              paddingRight: "10px"
                            }}
                          >
                            {maxcountObj - countObj} of {maxcountObj}{" "}
                            conversions remaining
                          </span>
                        ) : null}

                        {!viewArlas && countObj <= maxcountObj ? (
                          <button
                            id="convert"                            
                            className="arlas-btn primary"
                            data-toggle="modal"
                            onClick={handleConvert}
                            disabled={enableSubmitButton}
                            style={{
                              marginTop: "10px",
                              paddingLeft: "40px",
                              paddingRight: "40px"
                            }}
                          >
                            <Loading loading={converting} />
                            Convert to ARLAS
                          </button>
                        ) : (
                          <button
                            id="arlasconvert"                           
                            className="arlas-btn primary"
                            data-toggle="modal"
                            onClick={handleArlasConvert}
                            disabled={enableSubmitButton}
                            style={{
                              marginTop: "10px",
                              paddingLeft: "60px",
                              paddingRight: "60px"
                            }}
                          >
                            <Loading loading={converting} />
                            View ARLAS
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </section>

      <section id="actionbuttons" className="action-buttons-group">
        <div className="action-buttons">
          <div className="row m-0">
            <div className="col-3 p-0 position-relative">
              <button
                id="history"
                className="arlas-btn primary"
                data-toggle="modal"
                data-target="#historymodal"
                hidden={true}
                onClick={() => handleHistoryClick(true)}
              >
                History
              </button>
            </div>
            <div className="col-9 text-right p-0 position-relative">
              {/* <button
                id="convert"
                className="arlas-btn primary mr-20"
                data-toggle="modal"
                onClick={handleConvert}
                disabled={enableSubmitButton}
              >
                <Loading loading={converting} />
                Convert to ARLAS
              </button> */}
              {/* <button
                id="downloadarlas"
                className="arlas-btn primary"
                onClick={handleDownloadFile}
                disabled={enableDownloadButton}
              >
                <Loading loading={converting} />
                Download ARLAS
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </AppLayout>
  );
};

export default withAuth(CreateJob);
